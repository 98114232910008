import { useMutation, useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import DefaultImage from '@public/images/no-image-default.png'
import EnvelopeIcon from '@public/svg/icons/envelope.svg'
import MaskIcon from '@public/svg/icons/mask-icon.svg'
import PaperPlaneIcon from '@public/svg/icons/paper-plane.svg'
import BagIcon from '@public/svg/icons/working.svg'

import GembahReassignProjectsModal from '@dialog/GembahReassignProjectsModal'

import PopButton from '@forms/PopButton'

import { currentAccountUser, featureFlags } from '@lib/apollo/apolloCache'
import {
  COMMON_WHITE,
  GRAY_NICKELARCADE,
  GRAY_WII,
  RED_JEM,
  TEAL_DOLLHOUSE,
  TEAL_FURBY,
  TEAL_LITEBRITE,
  TEAL_MONOPOLY,
  TEAL_ROBOTS,
} from '@lib/colors'
import { COUNTRIES } from '@lib/constants'
import { getCountryImageUrl } from '@lib/dataUtils'
import { FONTS } from '@lib/theme'
import { hasPermission, isAdminManager } from '@lib/userAuth'

import { RESEND_USER_INVITE } from '@graphql/users/mutators'

import {
  determineUserBadgeColor,
  masqueradeUser,
  normalizeUser,
} from '@components_pop/AdminConsole/utils'
import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'
import UserLetterAvatar from '@components_pop/users/UserLetterAvatar'

import useBreakpoint from '@hooks/useBreakpoint'
import useToast from '@hooks/useToast'

const MemberBadge = styled('div')(({ usertype, isMobile }) => ({
  position: 'relative',
  width: isMobile ? 10 : 20,
  background: determineUserBadgeColor(usertype),
  '& > span': {
    color: TEAL_DOLLHOUSE,
    fontSize: 10,
    textTransform: 'uppercase',
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
    letterSpacing: 2,
    fontWeight: 500,
    top: '50%',
    left: '50%',
  },
}))

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 15,
    display: 'flex',
    background: COMMON_WHITE,
    boxShadow: '0 0 6px 0 #95918E4D',
    borderRadius: 10,
    overflow: 'hidden',
  },
  avatar: {
    borderRight: '2px solid rgba(222, 219, 217, 0.5)',
    width: 96,
    height: 96,
    '& .MuiAvatar-root': {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      background: TEAL_FURBY,
      fontSize: 32,
      fontWeight: 700,
      fontFamily: FONTS.MERRI_WEATHER,
      textTransform: 'uppercase',
    },
    '& > img': {
      maxWidth: 'inherit',
      height: 'inherit',
    },
  },
  rowDetailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rowDetailsTop: {
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowDetailsData: {
    display: 'flex',
    flexDirection: 'column',
    '& > span:first-child': {
      fontFamily: FONTS.MERRI_WEATHER,
      fontWeight: 700,
      fontSize: 20,
      color: TEAL_DOLLHOUSE,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& > span:last-child': {
      fontSize: 12,
      color: TEAL_ROBOTS,
    },
  },
  rowDetailsInfo: {
    display: 'flex',
    fontSize: 10,
    color: TEAL_ROBOTS,
    '& > span': {
      display: 'flex',
      padding: '5px 30px',
      alignItems: 'center',
      alignSelf: 'center',
      '&:first-child': {
        borderRight: `1px solid ${TEAL_MONOPOLY}`,
      },
      '& > img': {
        width: 18,
        marginRight: 10,
      },
      '& > div': {
        marginRight: 10,
        width: 18,
        height: 18,
      },
    },
  },
  rowDetailsBottom: {
    padding: '0 20px',
    background: TEAL_LITEBRITE,
    height: '100%',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > span:first-child': {
      color: TEAL_FURBY,
    },
    '& > span:last-child': {
      color: GRAY_NICKELARCADE,
    },
  },
  rowDetailsBottomAlternate: {
    '& > span:last-child': {
      color: RED_JEM,
    },
  },
  rowActions: {
    display: 'flex',
    padding: '0 20px',
    background: GRAY_WII,
    alignItems: 'center',
    '& button + button': {
      marginLeft: 10,
    },
  },
}))

const useMobileStyles = makeStyles(() => ({
  root: {
    marginBottom: 15,
    display: 'flex',
    background: COMMON_WHITE,
    boxShadow: '0 0 6px 0 #95918E4D',
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainWrapperInfo: {
    padding: 10,
    '& > div': {
      marginBottom: 10,
      paddingBottom: 10,
      borderBottom: `1px solid ${TEAL_MONOPOLY}`,
      '&:last-child': {
        margin: 0,
        padding: 0,
        border: 'none',
      },
    },
  },
  userDetailsWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  avatar: {
    width: 31,
    height: 31,
    marginRight: 10,
    borderRadius: '50%',
    overflow: 'hidden',
    '& .MuiAvatar-root': {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      background: TEAL_FURBY,
      fontSize: 12,
      fontWeight: 500,
      fontFamily: FONTS.MERRI_WEATHER,
      textTransform: 'uppercase',
    },
    '& > img': {
      maxWidth: 'inherit',
      height: 'inherit',
    },
  },
  rowDetailsWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rowDetailsTop: {
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowDetailsData: {
    display: 'flex',
    flexDirection: 'column',
    '& > span:first-child': {
      fontFamily: FONTS.MERRI_WEATHER,
      fontWeight: 700,
      fontSize: 14,
      color: TEAL_DOLLHOUSE,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& > span:last-child': {
      fontSize: 10,
      color: TEAL_ROBOTS,
    },
  },
  rowDetailsInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    color: TEAL_ROBOTS,
    '& > span': {
      display: 'flex',
      paddingBottom: 5,
      '& > img': {
        width: 18,
        marginRight: 10,
      },
      '& > div': {
        marginRight: 10,
        width: 18,
        height: 18,
      },
    },
  },
  rowDetailsBottom: {
    padding: '5px 10px',
    background: TEAL_LITEBRITE,
    height: '100%',
    display: 'flex',
    fontSize: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > span:first-child': {
      color: TEAL_FURBY,
    },
    '& > span:last-child': {
      color: GRAY_NICKELARCADE,
    },
  },
  rowDetailsBottomAlternate: {
    '& > span:last-child': {
      color: RED_JEM,
    },
  },
  rowActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      margin: 0,
      '& + button': {
        marginLeft: 10,
      },
    },
  },
}))

const MobileUserRow = ({
  user,
  onUserClick,
  isViewingUserGembah,
  loading,
  setIsReassignModalOpen,
  hasPermissions,
  showUserType = true,
  handleResendUserInvite,
  isMasqueradeActive,
}) => {
  const classes = useMobileStyles()

  const {
    lastLogin,
    fullName,
    firstName,
    lastName,
    email,
    companyName,
    countryCode,
    isUserGembah,
    maxRoleLabel,
    isMe,
    avatar,
    userId,
    userType,
    isInvited,
  } = user ?? {}

  return (
    <div className={classes.root}>
      {showUserType && <MemberBadge usertype={userType} isMobile />}
      <div className={classes.mainWrapper}>
        <div className={classes.mainWrapperInfo}>
          <div className={classes.userDetailsWrapper} onClick={() => onUserClick(user)}>
            <div className={classes.avatar}>
              {isInvited ? (
                <img src={DefaultImage.src} alt="invited" />
              ) : (
                <UserLetterAvatar
                  alt={fullName}
                  src={avatar?.url}
                  firstName={firstName}
                  lastName={lastName}
                />
              )}
            </div>
            <div className={classes.rowDetailsData}>
              <span>{isInvited ? email : fullName}</span>
              <span>{companyName}</span>
            </div>
          </div>
          {!isInvited && (
            <div>
              <div className={classes.rowDetailsInfo}>
                <span>
                  <SvgLoader {...EnvelopeIcon} />
                  {email}
                </span>
                <span>
                  <img src={getCountryImageUrl(countryCode)} alt={countryCode} />
                  {COUNTRIES[countryCode]}
                </span>
              </div>
            </div>
          )}

          {hasPermissions &&
            (isInvited ? (
              <div className={classes.rowActions}>
                <div>
                  <PopButton
                    onClick={handleResendUserInvite}
                    btnStyle="simple"
                    size="small"
                    iconHeight={20}
                    svgIconRight={PaperPlaneIcon}
                    disabled={loading}
                    loading={loading}
                  >
                    resend invite
                  </PopButton>
                </div>
              </div>
            ) : (
              isViewingUserGembah &&
              (isMasqueradeActive || isUserGembah) && (
                <div>
                  <div className={classes.rowActions}>
                    {isMasqueradeActive && (
                      <PopButton
                        btnStyle="simple"
                        size="small"
                        iconHeight={20}
                        svgIconRight={MaskIcon}
                        onClick={() => masqueradeUser(userId)}
                      >
                        masquerade
                      </PopButton>
                    )}
                    {isUserGembah && (
                      <PopButton
                        btnStyle="simple"
                        size="small"
                        iconHeight={20}
                        svgIconRight={BagIcon}
                        onClick={() => setIsReassignModalOpen(true)}
                      >
                        reassign work
                      </PopButton>
                    )}
                  </div>
                </div>
              )
            ))}
        </div>
        <div
          className={clsx(classes.rowDetailsBottom, {
            [classes.rowDetailsBottomAlternate]: isInvited,
          })}
        >
          <span>{`${isMe ? 'You - ' : ' '} ${maxRoleLabel}`}</span>
          <span>
            {isInvited
              ? 'Invited, Not Yet Responded'
              : `Last Activity: ${lastLogin ? moment(lastLogin).format('MMM DD, YYYY') : '-'}`}
          </span>
        </div>
      </div>
    </div>
  )
}

MobileUserRow.propTypes = {
  user: PropTypes.object,
  onUserClick: PropTypes.func,
  showUserType: PropTypes.bool,
  isInvited: PropTypes.bool,
  hasPermissions: PropTypes.bool,
  isUserGembah: PropTypes.bool,
  isViewingUserGembah: PropTypes.bool,
  loading: PropTypes.bool,
  isMasqueradeActive: PropTypes.bool,
  userType: PropTypes.string,
  companyName: PropTypes.string,
  countryCode: PropTypes.string,
  handleResendUserInvite: PropTypes.func,
  setIsReassignModalOpen: PropTypes.func,
}

const DesktopUserRow = ({
  user,
  onUserClick,
  isViewingUserGembah,
  loading,
  setIsReassignModalOpen,
  hasPermissions,
  showUserType = true,
  handleResendUserInvite,
  isMasqueradeActive,
}) => {
  const classes = useStyles()

  const {
    lastLogin,
    fullName,
    firstName,
    lastName,
    email,
    companyName,
    countryCode,
    isUserGembah,
    maxRoleLabel,
    isMe,
    avatar,
    userId,
    userType,
    isInvited,
  } = user ?? {}

  return (
    <div className={classes.root}>
      {showUserType && (
        <MemberBadge usertype={userType}>
          <span>{userType}</span>
        </MemberBadge>
      )}
      <div className={classes.avatar}>
        {isInvited ? (
          <img src={DefaultImage.src} alt="invited" />
        ) : (
          <UserLetterAvatar
            alt={fullName}
            src={avatar?.url}
            firstName={firstName}
            lastName={lastName}
          />
        )}
      </div>

      <div className={classes.rowDetailsWrapper} onClick={() => onUserClick(user)}>
        <div className={classes.rowDetailsTop}>
          <div className={classes.rowDetailsData}>
            <span>{isInvited ? email : fullName}</span>
            <span>{companyName}</span>
          </div>

          {!isInvited ? (
            <div className={classes.rowDetailsInfo}>
              <span>
                <SvgLoader {...EnvelopeIcon} />
                {email}
              </span>
              <span>
                <img src={getCountryImageUrl(countryCode)} alt={countryCode} />
                {COUNTRIES[countryCode]}
              </span>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div
          className={clsx(classes.rowDetailsBottom, {
            [classes.rowDetailsBottomAlternate]: isInvited,
          })}
        >
          <span>{`${isMe ? 'You - ' : ' '} ${maxRoleLabel}`}</span>
          <span>
            {isInvited
              ? 'Invited, Not Yet Responded'
              : `Last Activity: ${lastLogin ? moment(lastLogin).format('MMM DD, YYYY') : '-'}`}
          </span>
        </div>
      </div>
      {hasPermissions &&
        (isInvited ? (
          <div className={classes.rowActions}>
            <PopButton
              onClick={handleResendUserInvite}
              btnStyle="simple"
              svgIconRight={PaperPlaneIcon}
              disabled={loading}
              loading={loading}
            >
              resend invite
            </PopButton>
          </div>
        ) : (
          isViewingUserGembah &&
          (isMasqueradeActive || isUserGembah) && (
            <div className={classes.rowActions}>
              {isMasqueradeActive && (
                <PopButton
                  btnStyle="square"
                  svgIconRight={MaskIcon}
                  onClick={() => masqueradeUser(userId)}
                />
              )}
              {isUserGembah && (
                <PopButton
                  btnStyle="square"
                  svgIconRight={BagIcon}
                  onClick={() => setIsReassignModalOpen(true)}
                />
              )}
            </div>
          )
        ))}
    </div>
  )
}

DesktopUserRow.propTypes = {
  user: PropTypes.object,
  onUserClick: PropTypes.func,
  showUserType: PropTypes.bool,
  isInvited: PropTypes.bool,
  hasPermissions: PropTypes.bool,
  isUserGembah: PropTypes.bool,
  isViewingUserGembah: PropTypes.bool,
  loading: PropTypes.bool,
  isMasqueradeActive: PropTypes.bool,
  userType: PropTypes.string,
  companyName: PropTypes.string,
  countryCode: PropTypes.string,
  handleResendUserInvite: PropTypes.func,
  setIsReassignModalOpen: PropTypes.func,
}

const UserRow = ({ user, onUserClick, showUserType = true }) => {
  const { addToast } = useToast()
  const { isDesktop } = useBreakpoint()
  const viewingUser = useReactiveVar(currentAccountUser)
  const { isMasqueradeActive } = useReactiveVar(featureFlags)

  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false)
  const isViewingUserGembah = hasPermission.GEMBAH(viewingUser)
  const canManageUser = isAdminManager(viewingUser, user)

  const [resendUserInvite, { loading }] = useMutation(RESEND_USER_INVITE)

  const handleResendUserInvite = () => {
    resendUserInvite({ variables: { userEmail: user.email } }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could not resend invite',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: `Invite has been sent to ${user.email}`,
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }
  const normalizedUser = normalizeUser(user)
  normalizedUser.isMe = user?.id === viewingUser?.id

  const Component = isDesktop ? DesktopUserRow : MobileUserRow
  return (
    <>
      <Component
        user={normalizedUser}
        onUserClick={onUserClick}
        isViewingUserGembah={isViewingUserGembah}
        loading={loading}
        setIsReassignModalOpen={setIsReassignModalOpen}
        hasPermissions={canManageUser}
        showUserType={showUserType}
        handleResendUserInvite={handleResendUserInvite}
        isMasqueradeActive={isMasqueradeActive}
      />
      {isReassignModalOpen && (
        <GembahReassignProjectsModal
          userFrom={user}
          onClose={() => setIsReassignModalOpen(false)}
          onSuccessCallback={() => setIsReassignModalOpen(false)}
        />
      )}
    </>
  )
}

UserRow.propTypes = {
  user: PropTypes.object,
  onUserClick: PropTypes.func,
  showUserType: PropTypes.bool,
}

export default UserRow
