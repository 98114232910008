import { gql } from '@apollo/client'

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $accountType: AccountVariantChoices!
    $profileInfo: AccountProfileInput
    $withUser: Boolean
  ) {
    createAccount(accountType: $accountType, profileInfo: $profileInfo, withUser: $withUser) {
      account {
        id
      }
      accountUser {
        id
      }
    }
  }
`

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($accountId: ID!) {
    deleteAccount(accountId: $accountId) {
      account {
        id
      }
    }
  }
`

export const EDIT_STAFF_USER = gql`
  mutation EditGembahUser($userId: ID!, $userData: GembahUserInput!) {
    editGembahUser(userId: $userId, userData: $userData) {
      user {
        id
        coveredByEmail
        coveredByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer($userId: ID!, $userData: CustomerInput!) {
    editCustomer(userId: $userId, userData: $userData) {
      user {
        id
      }
    }
  }
`

export const EDIT_CONTRACTOR = gql`
  mutation EditContractor($userId: ID!, $userData: ContractorInput!) {
    editContractor(userId: $userId, userData: $userData) {
      user {
        id
      }
    }
  }
`

export const DELETE_STAFF_USER = gql`
  mutation DeleteGembahUser($userId: ID!) {
    deleteGembahUser(userId: $userId) {
      trashedUser {
        id
      }
    }
  }
`

export const DELETE_CONTRACTOR = gql`
  mutation DeleteContractor($userId: ID!) {
    deleteContractor(userId: $userId) {
      trashedUser {
        id
      }
    }
  }
`

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($userId: ID!) {
    deleteCustomer(userId: $userId) {
      trashedUser {
        id
      }
    }
  }
`

export const CREATE_STAFF_USER = gql`
  mutation CreateGembahUserWithRole(
    $email: String!
    $firstName: String!
    $lastName: String!
    $location: String!
    $role: String!
    $phone: String!
    $title: String!
    $coveredByUserId: String
    $coveredByEmail: String!
  ) {
    createGembahUserWithRole(
      email: $email
      firstName: $firstName
      lastName: $lastName
      location: $location
      role: $role
      phone: $phone
      title: $title
      coveredByUserId: $coveredByUserId
      coveredByEmail: $coveredByEmail
    ) {
      user {
        id
        firstName
        lastName
        isActive
        isStaff
        staffUser {
          id
        }
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $companyId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $location: String!
    $phone: String!
    $title: String!
    $isActive: Boolean
  ) {
    createCustomer(
      companyId: $companyId
      email: $email
      firstName: $firstName
      lastName: $lastName
      location: $location
      phone: $phone
      title: $title
      isActive: $isActive
    ) {
      user {
        id
        firstName
        lastName
        isActive
      }
    }
  }
`

export const CREATE_CONTRACTOR = gql`
  mutation CreateContractor(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $title: String!
  ) {
    createContractor(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      title: $title
      defaultHourlyRateData: { amount: 20, currency: "USD" } # placeholder, to be updated later
    ) {
      user {
        id
        firstName
        lastName
        isActive
      }
    }
  }
`

export const RESEND_USER_INVITE = gql`
  mutation ResendUserInvite($userEmail: String!) {
    resendUserInvite(userEmail: $userEmail) {
      user {
        id
      }
    }
  }
`

export const TOGGLE_PROJECT_DESIGN_CHAT_CUSTOMER = gql`
  mutation ToggleProjectDesignChatCustomer($userId: ID!, $projectId: ID!, $isAuthorized: Boolean!) {
    toggleProjectDesignChatCustomer(
      userId: $userId
      projectId: $projectId
      isAuthorized: $isAuthorized
    ) {
      project {
        id
        mutedUsers {
          id
          __typename
        }
        designChatCustomers {
          user {
            id
          }
        }
        __typename
      }
      __typename
    }
  }
`

export const ASSIGN_STAFF_USER_TO_PROJECT = gql`
  mutation AssignStaffUserToProject(
    $projectId: ID!
    $projectStaffUserType: String!
    $staffUserId: ID!
  ) {
    assignStaffUserToProject(
      projectId: $projectId
      projectStaffUserType: $projectStaffUserType
      staffUserId: $staffUserId
    ) {
      projectStaffUser {
        staffUser {
          id
        }
      }
    }
  }
`

export const REMOVE_STAFF_USER_FROM_PROJECT = gql`
  mutation RemoveStaffUserFromProject($projectId: ID!, $staffUserId: ID!) {
    removeStaffUserFromProject(projectId: $projectId, staffUserId: $staffUserId) {
      projectStaffUser {
        staffUser {
          id
          deletedAt
        }
      }
    }
  }
`

export const ASSIGN_CONTRACTOR_TO_PROJECT = gql`
  mutation AssignContractorToProject($projectId: ID!, $contractorId: ID!) {
    assignContractorToProject(projectId: $projectId, contractorId: $contractorId) {
      projectContractor {
        contractor {
          id
        }
      }
    }
  }
`

export const REMOVE_CONTRACTOR_FROM_PROJECT = gql`
  mutation RemoveContractorFromProject($projectId: ID!, $contractorId: ID!) {
    removeContractorFromProject(projectId: $projectId, contractorId: $contractorId) {
      projectContractor {
        contractor {
          id
          deletedAt
        }
      }
    }
  }
`

export const ADD_CUSTOMER_TO_PROJECT = gql`
  mutation ($userId: ID!, $projectId: ID!) {
    addCustomerToProject(userId: $userId, projectId: $projectId) {
      project {
        team {
          id
          userId
        }
      }
    }
  }
`

export const BULK_REASSIGN_PROJECTS_TO_STAFF_USER = gql`
  mutation BulkReassignProjectsToStaffUser($fromStaffUserId: ID!, $toStaffUserId: ID!) {
    bulkReassignProjectsToStaffUser(
      fromStaffUserId: $fromStaffUserId
      toStaffUserId: $toStaffUserId
    ) {
      projectsList {
        id
      }
    }
  }
`

export const GRANT_EXTERNAL_CHANNEL_ACCESS_TO_CONTRACTOR = gql`
  mutation GrantExternalChannelAccessToContractor($projectId: ID!, $userId: ID!) {
    grantExternalChannelAccessToContractor(projectId: $projectId, userId: $userId) {
      user {
        id
      }
    }
  }
`

export const REVOKE_EXTERNAL_CHANNEL_ACCESS_FROM_CONTRACTOR = gql`
  mutation RevokeExternalChannelAccessFromContractor($projectId: ID!, $userId: ID!) {
    revokeExternalChannelAccessFromContractor(projectId: $projectId, userId: $userId) {
      user {
        id
      }
    }
  }
`
