import { gql } from '@apollo/client'

export const UPDATE_USER_AVAILABILITY = gql`
  mutation UpdateUserAvailability($availability: String!, $userId: ID!) {
    updateUserAvailability(availability: $availability, userId: $userId) {
      user {
        id
      }
    }
  }
`

export const SET_TIME_ZONE = gql`
  mutation SetTimeZone($timeZone: String!) {
    setTimeZone(timeZone: $timeZone) {
      user {
        id
      }
    }
  }
`

export const ACCEPT_CUSTOMER_TERMS = gql`
  mutation AcceptCustomerTerms($termsVersion: String) {
    acceptCustomerTerms(termsVersion: $termsVersion) {
      acceptedTermsEntry {
        acceptedVersion
        acceptedAt
      }
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($currentPassword: String!, $newPassword: String!) {
    updateCurrentUserPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
    }
  }
`

export const UPDATE_ACCOUNT_USER_PROFILE = gql`
  mutation UpdateUserProfile($profileInfo: UserProfileInput!) {
    updateUserProfile(profileInfo: $profileInfo) {
      userProfile {
        id
      }
    }
  }
`

export const UPDATE_ACCOUNT_USER_PROFILE_AND_STATUS = gql`
  mutation UpdateUserProfileAndStatus(
    $profileInfo: UserProfileInput!
    $accountUserId: ID!
    $status: AccountUserStatusChoices
  ) {
    updateUserProfile(profileInfo: $profileInfo) {
      userProfile {
        id
      }
    }

    updateAccountUser(accountUserId: $accountUserId, status: $status) {
      accountUser {
        id
      }
    }
  }
`

export const CREATE_ACCOUNT_USER = gql`
  mutation CreateAccountUsers($invitees: [InviteInput]!, $accountId: ID, $isGembah: Boolean) {
    createAccountUsers(invitees: $invitees, accountId: $accountId, isGembah: $isGembah) {
      accountUsers {
        id
      }
    }
  }
`

export const UPDATE_ACCOUNT_USER = gql`
  mutation UpdateAccountUser(
    $accountUserId: ID!
    $status: AccountUserStatusChoices
    $roleInput: RoleInput
  ) {
    updateAccountUser(accountUserId: $accountUserId, status: $status, roleInput: $roleInput) {
      accountUser {
        id
      }
    }
  }
`

export const DELETE_ACCOUNT_USER = gql`
  mutation DeleteAccountUser($accountUserId: ID!) {
    deleteAccountUser(accountUserId: $accountUserId) {
      accountUser {
        id
      }
    }
  }
`

// export const ACCEPT_CONTRACTOR_TERMS = gql`
//   mutation AcceptContractorTerms ($termsVersion: String) {
//     acceptContractorTerms (termsVersion: $termsVersion) {
//       acceptedTermsEntry {
//         acceptedVersion
//         acceptedAt
//       }
//     }
//   }
// `
