import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'

import { NativeSelect } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ArrowIcon from '@public/svg/icons/arrow-down.svg'

import PopButton from '@forms/PopButton'

import { companiesFilterByType, paginationPage } from '@lib/apollo/apolloCache'
import { TEAL_DOLLHOUSE } from '@lib/colors'
import { serializeData } from '@lib/tracking'
import { ACCOUNT_TYPE_LABELS, ACCOUNT_TYPES } from '@lib/userAuth'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  dropIcon: {
    top: 'calc(50% - 7px)',
    width: 11,
  },
  select: {
    color: TEAL_DOLLHOUSE,
    width: 'auto',
    fontSize: 14,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

const CompaniesFilters = () => {
  const classes = useStyles()
  const userTypeFilter = useReactiveVar(companiesFilterByType)
  const [selectType, setSelectedType] = useState(null)

  return (
    <div className={classes.root}>
      <NativeSelect
        classes={{ select: classes.select, icon: classes.dropIcon }}
        defaultValue=""
        value={selectType ?? ''}
        inputProps={{
          name: 'accountType',
        }}
        onChange={(e) => {
          companiesFilterByType([e.target.value])
          paginationPage(1)
          setSelectedType(e.target.value)
        }}
        IconComponent={(props) => <SvgLoader {...ArrowIcon} {...props} />}
        disableUnderline
      >
        <option value="" disabled>
          Account Type
        </option>
        {Object.entries(ACCOUNT_TYPES)
          .filter(([, value]) => value !== ACCOUNT_TYPES.GEMBAH)
          .map(([key, value]) => (
            <option key={key} value={value}>
              {ACCOUNT_TYPE_LABELS[value]}
            </option>
          ))}
      </NativeSelect>
      <PopButton
        disabled={!userTypeFilter}
        onClick={() => {
          companiesFilterByType([
            ACCOUNT_TYPES.CREATOR,
            ACCOUNT_TYPES.FACTORY,
            ACCOUNT_TYPES.EXPERT,
          ])
          paginationPage(1)
          setSelectedType(null)
        }}
        style={{ marginLeft: 20 }}
        size="small"
        btnStyle="simple"
        data-tracking-info={serializeData({
          id: 'admin-console_companies-clear-filters-button_click',
        })}
      >
        clear filters
      </PopButton>
    </div>
  )
}

export default CompaniesFilters
