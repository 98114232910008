import PropTypes from 'prop-types'
import React from 'react'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'

import FormDialogSubmittingOverlay from './FormDialogSubmittingOverlay'
import GembahDialogTitle from './GembahDialogTitle'
import GembahFormSuccessDialog from './GembahFormSuccessDialog'

const GembahConfirmDialog = ({
  title,
  description,
  onClose,
  onAccept,
  acceptButtonLabel,
  closeButtonLabel,
  isSuccess,
  successText,
  isSubmitting,
}) => {
  if (isSuccess) {
    return (
      <GembahFormSuccessDialog
        data-test-id="confirm-dialog-success"
        onClose={onClose}
        successText={successText}
        buttonLabel="Close"
        onButtonClick={onClose}
      />
    )
  }

  return (
    <RootDialog
      open
      fullWidth
      maxWidth="xs"
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        onClose()
      }}
      data-test-id="admin--create-company-dialog"
    >
      <FormDialogSubmittingOverlay open={isSubmitting} />
      <GembahDialogTitle onClose={onClose}>{title}</GembahDialogTitle>
      {description && <RootDialogContent>{description}</RootDialogContent>}
      <RootDialogActions>
        {onClose && (
          <PopButton
            size="small"
            btnStyle="simple"
            onClick={onClose}
            data-test-id="dialog-confirm-close-button"
          >
            {closeButtonLabel}
          </PopButton>
        )}
        {onAccept && (
          <PopButton
            loading={isSubmitting}
            size="small"
            onClick={onAccept}
            data-test-id="dialog-confirm-accept-button"
          >
            {acceptButtonLabel}
          </PopButton>
        )}
      </RootDialogActions>
    </RootDialog>
  )
}

GembahConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  successText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.node,
  acceptButtonLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  isSubmitting: PropTypes.bool,
}

GembahConfirmDialog.defaultProps = {
  title: 'Are you sure?',
  description: PropTypes.shape({}),
  acceptButtonLabel: 'Confirm',
  closeButtonLabel: 'Cancel',
  successText: 'Action successfully executed',
  isSubmitting: false,
}

export default GembahConfirmDialog
