import useMediaQuery from '@material-ui/core/useMediaQuery'

import { DESKTOP_MAX, DESKTOP_MIN, MOBILE_MAX, MOBILE_MIN } from '@lib/theme'

const DEFAULT_BREAKPOINTS = {
  isDesktop: [DESKTOP_MIN, DESKTOP_MAX],
  isMobile: [MOBILE_MIN, MOBILE_MAX],
}

const OPTIONS = {
  noSsr: true,
}

const useBreakpoint = (customBreakpoints = {}) => {
  return Object.entries({ ...DEFAULT_BREAKPOINTS, ...customBreakpoints }).reduce(
    (variants, [variant, breakPoints]) => {
      const [start, end] = breakPoints
      return {
        ...variants,
        [variant]: useMediaQuery((theme) => theme.breakpoints.between(start, end), OPTIONS),
      }
    },
    {}
  )
}

export default useBreakpoint
