import clsx from 'clsx'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  COMMON_WHITE,
  GOLDER_ROD,
  GOLDER_ROD_1000,
  GREEN_CABBAGE_PATCH,
  GREEN_GLO_WORM,
  PURPLE_BEANIE_BABY,
  PURPLE_TROLLS,
  RED_POWERRANGERS,
  RED_SURPRISE,
} from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import useToast from '@hooks/useToast'

// Constants
export const TOAST_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  NEUTRAL: 'neutral',
}
export const MAX_TOASTS = 5
export const DEFAULT_TOAST_CLOSE = 3000

const useStyles = makeStyles((theme) => ({
  toastWrapper: {
    position: 'fixed',
    top: 80,
    left: 0,
    right: 0,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // This is required because the modal backdrop has 1300, and we need to show this over it
    zIndex: 1301,
  },
  toast: {
    padding: '10px 20px',
    width: '100%',
    fontSize: 18,
    fontStyle: 'italic',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: COMMON_WHITE,
    marginBottom: 5,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: '10px 75px',
    },
  },
  toastMessage: {
    maxWidth: '80%',
    maxHeight: 100,
    overflowY: 'auto',
  },
  toastBtn: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  success: {
    background: GREEN_GLO_WORM,
    color: GREEN_CABBAGE_PATCH,
  },
  error: {
    background: RED_SURPRISE,
    color: RED_POWERRANGERS,
  },
  warning: {
    background: GOLDER_ROD,
    color: GOLDER_ROD_1000,
  },
  info: {
    background: PURPLE_BEANIE_BABY,
    color: PURPLE_TROLLS,
  },
}))

const ToastView = () => {
  const classes = useStyles()

  const { toasts, deleteToast } = useToast()

  return (
    <div data-test-id="gembah-test-toast-wrapper" className={classes.toastWrapper}>
      {toasts.map(({ id, message, onClose, type, noManualClose }) => (
        <div
          key={id}
          className={clsx(classes.toast, classes[type])}
          data-test-id={`toast-notification-${id}`}
        >
          <div className={classes.toastMessage} data-test-id={`toast-notification-message-${type}`}>
            {typeof message === 'function' ? message() : message}
          </div>
          {!noManualClose && (
            <span
              onClick={() => deleteToast(id, onClose)}
              data-test-id="toast-notification-message-close-btn"
              className={classes.toastBtn}
            >
              dismiss
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default ToastView
