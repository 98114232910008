/* eslint react/prop-types: 0 */
import React from 'react'

import { Skeleton } from '@material-ui/lab'

import useBreakpoint from '@hooks/useBreakpoint'

const ChatLoader = ({ index }) => {
  const isEven = index % 2 === 0

  return (
    <div
      style={{
        flexDirection: isEven ? 'row' : 'row-reverse',
        alignItems: 'end',
        padding: '15px 15px 0',
      }}
    >
      <div>
        <Skeleton variant="circle" width={40} height={40} />
      </div>
      <div
        style={{
          margin: '0 10px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: isEven ? 'flex-start' : 'flex-end',
        }}
      >
        <div style={{ display: 'flex', justifyContent: isEven ? 'flex-start' : 'flex-end' }}>
          <Skeleton style={{ marginRight: 10 }} width={60} />
          <Skeleton width={80} />
        </div>
        <Skeleton width={400} height={120} />
      </div>
    </div>
  )
}

const AttachmentLoader = () => (
  <div style={{ display: 'flex', justifyContent: 'end' }}>
    <Skeleton style={{ marginRight: 20 }} variant="rect" width={35} height={35} />
    <div>
      <div style={{ display: 'flex' }}>
        <Skeleton width={70} height={30} style={{ marginRight: 15 }} />
        <Skeleton width={120} height={30} />
      </div>
      <Skeleton width={200} height={30} />
    </div>
  </div>
)

const ProductionDashboardFiltersLoader = () => {
  const { isMobile } = useBreakpoint()

  return isMobile ? (
    <div style={{ flexDirection: 'column', alignItems: 'baseline' }}>
      <Skeleton variant="rect" width="30%" height={40} style={{ marginBottom: 10 }} />
      <Skeleton variant="rect" width="100%" height={40} style={{ marginBottom: 10 }} />
      <Skeleton variant="rect" width="100%" height={40} style={{ marginBottom: 10 }} />
    </div>
  ) : (
    <div style={{ justifyContent: 'end' }}>
      <Skeleton style={{ marginRight: 20 }} variant="rect" width={180} height={60} />
      <Skeleton width="30%" height={60} />
    </div>
  )
}

const ProjectLoader = () => (
  <div>
    <Skeleton style={{ marginRight: 20 }} variant="rect" width={80} height={60} />
    <Skeleton width="100%" height={60} />
  </div>
)

const CompanyLoader = () => {
  const { isMobile } = useBreakpoint()

  return (
    <div>
      {isMobile ? (
        <div style={{ width: 150 }}>
          <Skeleton width={150} height={30} />
          <Skeleton width={100} height={30} />
        </div>
      ) : (
        <>
          <Skeleton width={150} height={60} />
          <Skeleton width={100} height={60} style={{ marginRight: '40%' }} />
        </>
      )}
    </div>
  )
}

const UserLoader = () => {
  const { isMobile } = useBreakpoint()

  return (
    <div>
      {isMobile ? (
        <>
          <div style={{ width: 150 }}>
            <Skeleton height={30} />
            <Skeleton height={30} />
            <Skeleton height={30} />
          </div>
          <Skeleton width={150} height={60} />
        </>
      ) : (
        <>
          <div style={{ width: 150 }}>
            <Skeleton height={30} />
            <Skeleton height={30} />
          </div>
          <Skeleton width={150} height={60} />
          <Skeleton width={150} height={60} />
          <Skeleton width={150} height={60} />
          <Skeleton width={150} height={60} />
        </>
      )}
    </div>
  )
}

const CheckListLoader = () => (
  <div style={{ padding: '0 20px' }}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Skeleton variant="rect" height={24} width={24} style={{ marginRight: 10 }} />
      <div style={{ width: '100%' }}>
        <Skeleton height={60} />
      </div>
    </div>
  </div>
)

const DashboardStats = () => {
  const { isMobile } = useBreakpoint()

  if (isMobile) {
    return <Skeleton width={168} height={32} />
  }

  return <Skeleton width={160} height={24} />
}

export default {
  project: ProjectLoader,
  prodDashFilters: ProductionDashboardFiltersLoader,
  company: CompanyLoader,
  user: UserLoader,
  checklist: CheckListLoader,
  chat: ChatLoader,
  attachment: AttachmentLoader,
  dashboardStats: DashboardStats,
}
