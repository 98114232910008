import {
  GREEN_MOON_SHOES,
  PURPLE_BEANIE_BABY,
  TEAL_POWERGLOVE,
  YELLOW_SUPER_SOAKER,
} from '@lib/colors'
import { USER_ID_SESSION_STORAGE_KEY } from '@lib/constants'
import {
  ACCOUNT_TYPES,
  ACCOUNT_USER_STATUS,
  hasPermission,
  maxPermission,
  maxRole,
  USER_ROLES,
  USER_ROLES_HIERARCHY,
  USER_ROLES_LABELS,
} from '@lib/userAuth'

import { ROUTES as EXTERNAL_ROUTES, ROUTES_NAMES as EXTERNAL_ROUTES_NAMES } from './External/utils'
import { ROUTES as INTERNAL_ROUTES, ROUTES_NAMES as INTERNAL_ROUTES_NAMES } from './Internal/utils'

export const masqueradeUser = (userId) => {
  localStorage.setItem(USER_ID_SESSION_STORAGE_KEY, userId)
  window.open('/')
}

const usersByRole = (permissionLevel) => (account) => {
  const accountUsers = account?.accountUsers ?? []

  return accountUsers.filter((accountUser) => {
    const requiredPermission = USER_ROLES_HIERARCHY[permissionLevel]
    return !requiredPermission ? false : maxPermission(accountUser) >= requiredPermission
  })
}

export const getUsersByRole = {
  MEMBER: usersByRole(USER_ROLES.MEMBER),
  ADMIN: usersByRole(USER_ROLES.ADMIN),
  OWNER: usersByRole(USER_ROLES.OWNER),
}

export const normalizeUser = (user = {}) => {
  const userAccount = user?.account
  const accountProfile = userAccount?.accountProfile

  const isLastAccountUser = userAccount?.accountUsers?.length === 1 && !!accountProfile
  const isInvited = user?.status === ACCOUNT_USER_STATUS.INVITE_PENDING
  const lastLogin = user?.user?.lastLogin
  const userType = userAccount?.type
  const countryCode = (user?.user?.profile?.location ?? accountProfile?.primaryLocation)?.country
  const companyName = accountProfile?.name
  const isUserGembah = hasPermission.GEMBAH(user)
  const maxUserRole = maxRole(user)
  const maxRoleLabel =
    USER_ROLES_LABELS[
      isUserGembah && maxUserRole?.role === USER_ROLES.OWNER
        ? USER_ROLES.SUPER_ADMIN
        : maxUserRole?.role
    ]
  const avatar = user?.user?.profile?.avatar

  return {
    ...user,
    avatar,
    lastLogin,
    accountProfile,
    maxRole: maxUserRole,
    maxRoleLabel,
    isLastAccountUser,
    isInvited,
    userType,
    countryCode,
    companyName,
    isUserGembah,
  }
}

export const determineUserBadgeColor = (userType) => {
  switch (userType) {
    case ACCOUNT_TYPES.CREATOR:
      return YELLOW_SUPER_SOAKER
    case ACCOUNT_TYPES.EXPERT:
      return PURPLE_BEANIE_BABY
    case ACCOUNT_TYPES.FACTORY:
      return GREEN_MOON_SHOES
    case ACCOUNT_TYPES.GEMBAH:
    default:
      return TEAL_POWERGLOVE
  }
}

export const availableAdminRoutes = (accountUser) => {
  const isOwner = hasPermission.OWNER(accountUser)

  if (hasPermission.GEMBAH(accountUser)) {
    const { gembah, ...restOfRoutes } = INTERNAL_ROUTES
    return {
      routes: isOwner ? INTERNAL_ROUTES : restOfRoutes,
      firstRoute: INTERNAL_ROUTES_NAMES.COMPANIES,
    }
  }

  const { billing, ...restOfRoutes } = EXTERNAL_ROUTES
  return {
    routes: isOwner ? EXTERNAL_ROUTES : restOfRoutes,
    firstRoute: EXTERNAL_ROUTES_NAMES.COMPANY,
  }
}
