/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { InputAdornment } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
  iconWrapper: {
    marginRight: 15,
    '& > div': {
      width: 20,
      height: 20,
    },
    '& > img': {
      width: 20,
      height: 20,
    },
  },
}))

const PopComboField = ({
  id,
  error,
  label,
  className,
  getOptionLabel,
  getOptionSelected,
  getOptionDisabled,
  onChange,
  FormLabelProps,
  inputRef,
  helperText,
  noOptionsText,
  placeholder,
  TextFieldProps,
  disableHelperText,
  classes,
  value,
  'data-test-id': dataTestId,
  fullWidth,
  options,
  disabled,
  loading,
  required,
  inputIcon,
  inputIconImage,
  ...rest
}) => {
  const autocompleteClasses = useStyles()

  const renderInput = (params) => {
    const newParams = {
      ...params,
      placeholder,
      InputProps: {
        ...params.InputProps,
        ...((inputIcon || inputIconImage) && {
          startAdornment: (
            <InputAdornment classes={{ root: autocompleteClasses.iconWrapper }} position="start">
              {inputIconImage && <img src={inputIconImage} alt={inputIconImage} />}
              {inputIcon && <SvgLoader {...inputIcon} />}
            </InputAdornment>
          ),
        }),
        disableUnderline: true,
        'data-test-id': dataTestId,
      },
    }
    return <TextField error={error} inputRef={inputRef} {...TextFieldProps} {...newParams} />
  }

  renderInput.propTypes = {
    params: PropTypes.object,
  }

  return (
    <FormControl className={className} fullWidth={fullWidth} variant="outlined">
      {label && (
        <FormLabel
          className={autocompleteClasses.label}
          htmlFor={id}
          error={error}
          {...FormLabelProps}
        >
          {label}
          {!!required && <span>*</span>}
        </FormLabel>
      )}
      <Autocomplete
        loadingText="Loading..."
        loading={loading}
        noOptionsText={noOptionsText}
        options={options}
        disabled={disabled}
        getOptionLabel={getOptionLabel || ((option) => option.text)}
        getOptionDisabled={getOptionDisabled}
        getOptionSelected={getOptionSelected || ((opt, selected) => opt.value === selected.value)}
        value={value}
        renderInput={renderInput}
        onChange={onChange}
        className={clsx(autocompleteClasses.root, autocompleteClasses.input, {
          [autocompleteClasses.autocompleteDisabled]: disabled,
        })}
        classes={{ inputRoot: autocompleteClasses.autocomplete, ...classes }}
        {...rest}
      />
      {disableHelperText
        ? null
        : error && (
            <FormHelperText
              className={autocompleteClasses.helperText}
              error={error}
              style={{ marginLeft: 0 }}
            >
              {helperText}
            </FormHelperText>
          )}
    </FormControl>
  )
}

PopComboField.defaultProps = { label: '', error: false, FormLabelProps: {} }

PopComboField.propTypes = {
  'data-test-id': PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  disableHelperText: PropTypes.bool,
  FormLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  TextFieldProps: PropTypes.object,
  inputRef: PropTypes.object,
  classes: PropTypes.object,
  inputIcon: PropTypes.object,
  inputIconImage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default PopComboField
