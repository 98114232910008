import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'

import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'

import { BRAND_WHITE, COMMON_WHITE, ICON_GREY_SECONDARY, TEAL_ROBOTS } from '@lib/colors'

const useStyles = makeStyles(() => ({
  popCheckbox: {
    backgroundColor: BRAND_WHITE,
    border: `solid 1px ${ICON_GREY_SECONDARY}`,
    borderRadius: 3,
    height: 18,
    width: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const PopCheckboxIcon = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.popCheckbox}>{children}</div>
}

const PopCheckBoxIconChecked = () => (
  <PopCheckboxIcon>
    <CheckIcon
      style={{
        fontSize: 18,
        color: COMMON_WHITE,
        background: TEAL_ROBOTS,
        borderRadius: 3,
      }}
    />
  </PopCheckboxIcon>
)

PopCheckboxIcon.propTypes = {
  children: PropTypes.node,
}

const useFormStyles = makeStyles(() => ({
  helperText: {
    fontStyle: 'italic',
  },
}))

const PopCheckbox = (props) => {
  return <Checkbox icon={<PopCheckboxIcon />} checkedIcon={<PopCheckBoxIconChecked />} {...props} />
}

const PopFormCheckbox = (props) => {
  const { name, label, control, labelClass, helperText, ...CheckboxProps } = props

  const classes = useFormStyles()

  return (
    <FormControl>
      <FormControlLabel
        label={label}
        classes={{ root: labelClass }}
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={false}
            render={({ value, onChange }) => (
              <PopCheckbox
                color="primary"
                {...CheckboxProps}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        }
      />
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

PopFormCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  control: PropTypes.object,
  labelClass: PropTypes.string,
}

export { PopCheckboxIcon, PopCheckBoxIconChecked, PopCheckbox, PopFormCheckbox }
