export const URL_REGEX =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$|^$/
export const normalizeURL = (str, isSecure = false) => {
  if (!str) return ''
  if (str.match(/^http[s]?:\/\//)) return str
  return `${isSecure ? 'https' : 'http'}://${str}`
}
export const normalizePhone = (phone) => {
  let intlPhone = phone || ''
  if (!intlPhone.startsWith('+') && intlPhone) {
    intlPhone = intlPhone.match(/\d+/gm).join('') || '' // extract only digits
    if (intlPhone.length && intlPhone.length === 10) {
      intlPhone = `+1${intlPhone}`
    } else {
      intlPhone = `+${intlPhone}`
    }
  }

  return intlPhone
}
