import { DialogActions, Dialog, DialogContent, withStyles } from '@material-ui/core'

import { MOBILE_BREAKPOINT } from '@lib/theme'

export const RootDialog = withStyles(() => ({
  root: {
    '& .MuiBackdrop-root': {
      background: 'rgba(247, 246, 245, 0.7)',
      backdropFilter: 'blur(3px)',
    },
  },
  paper: {
    borderRadius: 20,
    boxShadow: '0 0 15px rgba(149, 145, 142, 0.5)',
  },
}))(Dialog)

export const RootDialogContent = withStyles(() => ({
  root: {
    padding: 35,
    display: 'flex',
    flexDirection: 'column',
    '& > div:first-child': {
      marginBottom: 15,
    },
  },
}))(DialogContent)

export const RootDialogActions = withStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: '10px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > :not(:first-child)': {
      marginLeft: 0,
    },
    '& button': {
      width: '100%',
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
      '& button': {
        width: 'auto',
      },
    },
  },
}))(DialogActions)
