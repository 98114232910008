import { useReactiveVar } from '@apollo/client'
import moment from 'moment-timezone'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { currentUser } from '@lib/apollo/apolloCache'
import {
  GRAY_NES,
  GRAY_NICKELARCADE,
  GRAY_WII,
  RED_STRAWBERRY_SHORT_CAKE,
  TEAL_FURBY,
  TEAL_ROBOTS,
} from '@lib/colors'
import { MIME_TYPES_FOR_ICONS } from '@lib/constants'

import GembahTable from '@components_pop/Table'
import MediaIcon from '@components_pop/attachments/MediaIcon'

import { BILLING_DATE_FORMAT, INVOICE_STATUSES, INVOICE_STATUSES_LABELS } from './constants'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  tableSegment: {
    width: '100%',
    marginBottom: 40,
    '& table': {
      tableLayout: 'fixed',
      '& > thead > tr > th': {
        borderBottom: 'none',
        textTransform: 'none',
        '& > div': {
          color: GRAY_NICKELARCADE,
        },
      },
      '& > tbody > tr': {
        cursor: 'default',
        '& > td': {
          padding: 8,
        },
        '&:nth-child(odd) > td': {
          background: GRAY_WII,
        },
      },
    },
    '& > P': {
      fontSize: 18,
      paddingBottom: 10,
      color: TEAL_FURBY,
      borderBottom: `1px solid ${GRAY_NES}`,
    },
  },
  invoiceNo: {
    '& > a': {
      color: TEAL_ROBOTS,
    },
  },
  invoicePdf: {
    width: 20,
  },
  dueDateExceeded: {
    color: RED_STRAWBERRY_SHORT_CAKE,
  },
  emptyText: {
    color: GRAY_NICKELARCADE,
    fontSize: 13,
    width: '100%',
    textAlign: 'center',
    fontStyle: 'italic',
  },
}))

const EmptyState = () => {
  const classes = useStyles()

  return <div className={classes.emptyText}>Invoices will appear here once they are issued.</div>
}

const Billing = () => {
  const classes = useStyles()
  const user = useReactiveVar(currentUser)
  const router = useRouter()

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  useEffect(() => {
    if (!user?.stripeInvoices) router.push('/')
  }, [user])

  const invoices = user.stripeInvoices ?? []

  const openInvoices = invoices.filter((invoice) => invoice.status === INVOICE_STATUSES.OPEN)
  const otherInvoices = invoices.filter((invoice) => invoice.status !== INVOICE_STATUSES.OPEN)

  const openHeaders = [
    {
      name: 'number',
      text: 'Invoice Number',
      sortable: false,
    },
    {
      name: 'status',
      text: 'Status',
      sortable: false,
    },
    {
      name: 'date',
      text: 'Invoice Date',
      sortable: false,
    },
    {
      name: 'due',
      text: 'Due Date',
      sortable: false,
    },
    {
      name: 'amount',
      text: 'Amount ($USD)',
      sortable: false,
    },
    {
      name: 'pdf',
      text: 'PDF',
      width: 50,
      sortable: false,
    },
  ]

  const closedHeaders = [...openHeaders]

  closedHeaders.splice(3, 1, {
    name: 'paid_date',
    text: 'Paid Date',
    sortable: false,
  })

  const renderCorrectDueDate = (date, status) => {
    if (!date) return '-'

    const formattedDate = moment(date).format(BILLING_DATE_FORMAT)

    return status === INVOICE_STATUSES.OPEN && moment().diff(date, 'days') > 0 ? (
      <div className={classes.dueDateExceeded}>{formattedDate}</div>
    ) : (
      formattedDate
    )
  }

  const listItemsMappingDesktop = (invoice) => {
    return {
      cells: [
        {
          classNames: { [classes.invoiceNo]: true },
          content: (
            <a href={invoice.hostedInvoiceUrl} target="_blank" rel="noreferrer">
              {invoice.invoiceNumber}
            </a>
          ),
        },
        {
          content: INVOICE_STATUSES_LABELS[invoice.status],
        },
        {
          content: moment(invoice.invoiceDate).format(BILLING_DATE_FORMAT),
        },
        {
          content: renderCorrectDueDate(
            invoice.status !== INVOICE_STATUSES.OPEN ? invoice.invoicePaidDate : invoice.dueDate,
            invoice.status
          ),
        },
        {
          content: `${currencyFormatter.format(invoice.amount)}`,
        },
        {
          classNames: { [classes.invoicePdf]: true },
          content: (
            <a href={invoice.invoicePdf} target="_blank" rel="noreferrer">
              <MediaIcon mimetype={MIME_TYPES_FOR_ICONS.PDF} className={classes.invoicePdf} />
            </a>
          ),
        },
      ],
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableSegment}>
        <p>Open</p>

        <GembahTable
          tableName="billing-open-table"
          headers={openHeaders}
          emptyState={<EmptyState />}
          listItems={openInvoices}
          listItemsMap={listItemsMappingDesktop}
        />
      </div>

      <div className={classes.tableSegment}>
        <p>Closed</p>

        <GembahTable
          tableName="billing-closed-table"
          headers={closedHeaders}
          emptyState={<EmptyState />}
          listItems={otherInvoices}
          listItemsMap={listItemsMappingDesktop}
        />
      </div>
    </div>
  )
}

export default Billing
