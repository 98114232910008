import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-material-ui'

import { Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

import ArrowIcon from '@public/svg/icons/arrow-down.svg'
import LandlineIcon from '@public/svg/icons/landline-icon.svg'
import MobileIcon from '@public/svg/icons/mobile-icon.svg'

import { COUNTRIES_OPTIONS } from '@lib/constants'
import { getCountryImageUrl } from '@lib/dataUtils'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
  phoneRoot: {
    ...theme.formInputStyles.root,
    display: 'flex',
    borderRadius: 10,
    margin: '10px 0',
    '& > div:first-child': {
      borderRadius: '10px 0 0 10px',
      '& div': {
        borderRadius: '10px 0 0 10px',
      },
    },
    '& > div:last-child': {
      borderRadius: '0 10px 10px 0',
      '& div': {
        borderRadius: '0 10px 10px 0',
      },
    },
  },
  phoneInput: {
    ...theme.formInputStyles.input,
    margin: '0 !important',
    borderRadius: 0,
  },
  selectFormControl: {
    background: 'rgba(222, 219, 217, 0.5)',
    '& > div': {
      height: 52,
    },
  },
  selectRoot: {
    width: 60,
    height: 'inherit',
    padding: '0 10px !important',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      fontSize: 0,
    },
  },
  dropIcon: {
    top: 'calc(50% - 7px)',
    width: 15,
    marginRight: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  selectItem: {
    justifyContent: 'center',
  },
}))

export const PHONE_TYPE = {
  MOBILE: 'MOBILE',
  LANDLINE: 'LANDLINE',
}

const PopPhoneField = (props) => {
  const {
    error,
    label,
    id,
    helperText,
    FormLabelProps,
    fullWidth,
    className,
    disableHelperText,
    name,
    phoneTypeName = 'phoneType',
    phoneCountryName = 'phoneCountry',
    control,
    'data-test-id': dataTestId,
    multiline,
    required,
    type,
    disabled,
    ...InputProps
  } = props

  const classes = useStyles()
  const currentPhoneCountry = control.watchInternal(phoneCountryName) ?? 'US'

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && (
        <FormLabel className={classes.label} htmlFor={id} error={error} {...FormLabelProps}>
          {label}
          {!!required && <span>*</span>}
        </FormLabel>
      )}
      <div className={classes.phoneRoot}>
        <Controller
          name={phoneCountryName}
          defaultValue="US"
          control={control}
          render={({ value, onChange }) => (
            <FormControl classes={{ root: classes.selectFormControl }}>
              <Select
                classes={{ select: classes.selectRoot, icon: classes.dropIcon }}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  control.setValue(name, null)
                }}
                disabled={disabled}
                IconComponent={(iconProps) => <SvgLoader {...ArrowIcon} {...iconProps} />}
                disableUnderline
              >
                {COUNTRIES_OPTIONS.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    <img
                      loading="lazy"
                      width="20"
                      style={{ marginRight: 10 }}
                      src={getCountryImageUrl(country.value)}
                      alt={country.value}
                    />
                    <span>{country.text}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name={name}
          defaultValue={null}
          control={control}
          render={({ value, onChange }) => (
            <FormControl fullWidth>
              <ReactPhoneInput
                component={Input}
                country={currentPhoneCountry?.toLowerCase()}
                value={value}
                onChange={(_v, countryData, _event, formattedValue) => {
                  onChange(formattedValue)

                  if (currentPhoneCountry !== countryData?.countryCode?.toUpperCase()) {
                    control.setValue(phoneCountryName, countryData?.countryCode?.toUpperCase())
                  }
                }}
                inputProps={{
                  ...InputProps,
                  classes: { root: classes.phoneInput },
                  disableUnderline: true,
                  inputProps: { 'data-test-id': dataTestId, type },
                  disabled,
                  id,
                  error,
                  name,
                }}
                enableAreaCodes
              />
            </FormControl>
          )}
        />
        <Controller
          name={phoneTypeName}
          defaultValue={PHONE_TYPE.MOBILE}
          control={control}
          render={({ value, onChange }) => (
            <FormControl classes={{ root: classes.selectFormControl }}>
              <Select
                classes={{ select: classes.selectRoot, icon: classes.dropIcon }}
                value={value}
                onChange={onChange}
                IconComponent={(iconProps) => <SvgLoader {...ArrowIcon} {...iconProps} />}
                disabled={disabled}
                disableUnderline
              >
                <MenuItem classes={{ root: classes.selectItem }} value={PHONE_TYPE.MOBILE}>
                  <SvgLoader className={classes.icon} {...MobileIcon} />
                </MenuItem>
                <MenuItem classes={{ root: classes.selectItem }} value={PHONE_TYPE.LANDLINE}>
                  <SvgLoader className={classes.icon} {...LandlineIcon} />
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>

      {!disableHelperText && error && (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

PopPhoneField.defaultProps = {
  label: '',
  error: false,
  FormLabelProps: {},
  disableHelperText: false,
  multiline: false,
}

PopPhoneField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  phoneTypeName: PropTypes.string,
  phoneCountryName: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  disableHelperText: PropTypes.bool,
  disabled: PropTypes.bool,
  FormLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  control: PropTypes.object,
  className: PropTypes.string,
  'data-test-id': PropTypes.string,
  multiline: PropTypes.bool,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default PopPhoneField
