import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  externalUsersFilter,
  usersSearch,
  internalUsersFilterByLocation,
  internalUsersFilterByRole,
} from '@lib/apollo/apolloCache'
import { hasPermission } from '@lib/userAuth'

import { GET_ACCOUNT_USERS } from '@graphql/users/queries'

import UserDrawerContent from '@components_pop/AdminConsole/Shared/UserDrawerContent'
import UserRow from '@components_pop/AdminConsole/Shared/UserRow'
import { USER_PAGINATION_LIMIT } from '@components_pop/AdminConsole/constants'
import { getUsersByRole } from '@components_pop/AdminConsole/utils'
import Loader from '@components_pop/Loader'
import Pagination from '@components_pop/Pagination'
import usePagination from '@components_pop/Pagination/hooks'
import PopDrawer from '@components_pop/PopDrawer'

const Users = ({ userType }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isReset, setIsReset] = useState(false)
  const [page, setPage] = usePagination({ limit: USER_PAGINATION_LIMIT })

  const { data, loading: isLoading } = useQuery(GET_ACCOUNT_USERS, {
    variables: {
      accountType: userType,
    },
    skip: !isReset,
  })

  useEffect(() => {
    usersSearch('')
    externalUsersFilter(null)
    internalUsersFilterByLocation(null)
    internalUsersFilterByRole(null)
    setPage(1)
    setIsReset(true)
  }, [])

  const users = data?.accountUsers?.accountUsers ?? []
  const hasOwner = hasPermission.GEMBAH(currentUser)
    ? false
    : !!getUsersByRole.OWNER(currentUser?.account).length

  const handleOnClose = () => setCurrentUser(null)

  return (
    <div style={{ paddingBottom: 50 }}>
      <Loader loaderElementsCount={6} isLoadingContent={isLoading}>
        {users.map((user) => (
          <UserRow key={user.id} user={user} onUserClick={setCurrentUser} />
        ))}
        <Pagination
          page={page}
          itemsCount={data?.accountUsers?.totalCount ?? 0}
          onChange={setPage}
        />
      </Loader>

      <PopDrawer open={!!currentUser} onClose={handleOnClose} title="Edit User">
        <UserDrawerContent user={currentUser} onClose={handleOnClose} hasOwner={hasOwner} />
      </PopDrawer>
    </div>
  )
}

Users.propTypes = {
  userType: PropTypes.string,
}

export default Users
