import OfficeIcon from '@public/svg/icons/company.svg'
import BillingIcon from '@public/svg/icons/credit-cards-icon.svg'
import MembersIcon from '@public/svg/icons/users.svg'

import Billing from '@components_pop/AdminConsole/External/Billing'
import Company from '@components_pop/AdminConsole/External/Company'
import Members from '@components_pop/AdminConsole/External/Members'
import MembersActions from '@components_pop/AdminConsole/External/Members/MembersActions'

export const ROUTES_NAMES = {
  COMPANY: 'company',
  MEMBERS: 'members',
  BILLING: 'billing',
}

export const ROUTES = {
  [ROUTES_NAMES.COMPANY]: {
    name: 'Company',
    title: 'Company',
    url: 'company',
    component: Company,
    icon: OfficeIcon,
  },
  [ROUTES_NAMES.MEMBERS]: {
    name: 'Members',
    title: 'Members',
    url: 'members',
    component: Members,
    icon: MembersIcon,
    action: MembersActions,
  },
  [ROUTES_NAMES.BILLING]: {
    name: 'Billing',
    title: 'Billing',
    url: 'billing',
    component: Billing,
    icon: BillingIcon,
  },
}
