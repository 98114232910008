import { useMutation, useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input/input'
import * as Yup from 'yup'

import { PHONE_TYPE } from '@forms/PopPhoneField'

import { currentAccount, currentAccountUser } from '@lib/apollo/apolloCache'
import { normalizeURL, URL_REGEX } from '@lib/stringUtils'
import { hasPermission } from '@lib/userAuth'

import { UPDATE_ACCOUNT_PROFILE } from '@graphql/profile/mutators'
import { GET_AUTH_USER } from '@graphql/user/queries'

import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import CompanyView from './view'

const schema = Yup.object().shape({
  companyName: Yup.string(),
  phone: Yup.string()
    .nullable()
    .test({
      name: 'isValidPhoneNumber',
      test(v) {
        return (
          isValidPhoneNumber(v) ||
          this.createError({
            message: 'Please enter a valid phone number',
          })
        )
      },
    }),
  url: Yup.string()
    .trim()
    .transform((v) => normalizeURL(v, true))
    .matches(URL_REGEX, 'Please enter a valid URL'),
})

const CompanyContainer = () => {
  const { addToast } = useToast()

  const accountUser = useReactiveVar(currentAccountUser)
  const account = useReactiveVar(currentAccount)
  const {
    id,
    name: companyName,
    website: url,
    phone,
    phoneType,
    primaryLocation,
  } = account?.accountProfile

  const [updateAccountProfile, { loading: isSubmitting }] = useMutation(UPDATE_ACCOUNT_PROFILE, {
    refetchQueries: [{ query: GET_AUTH_USER }],
  })

  const defaultValues = {
    companyName,
    url,
    phone,
    phoneType: phoneType ?? PHONE_TYPE.MOBILE,
    location: primaryLocation?.country ?? '',
    phoneCountry: phone ? parsePhoneNumber(phone)?.country : 'US',
  }

  const form = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const { reset, trigger, register } = form

  const handleResetForm = () => reset(defaultValues)

  useEffect(() => {
    register({ name: 'location' })
  }, [register])

  const handleFormSubmit = (companyData) => {
    updateAccountProfile({
      variables: {
        accountId: id,
        profileInfo: {
          name: companyData.companyName,
          phone: companyData.phone,
          phoneType: companyData.phoneType,
          website: companyData.url,
          location: companyData.location,
        },
      },
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Your changes could not be saved',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: 'Your changes have been saved',
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  useEffect(() => {
    trigger()
  }, [])

  return (
    <CompanyView
      form={form}
      hasPermission={hasPermission.ADMIN(accountUser)}
      isSubmitting={isSubmitting}
      onFormSubmit={handleFormSubmit}
      onResetForm={handleResetForm}
    />
  )
}

export default CompanyContainer
