import { styled } from '@material-ui/core'

import { TEAL_DOLLHOUSE } from '@lib/colors'

import { determineUserBadgeColor } from '@components_pop/AdminConsole/utils'

// eslint-disable-next-line import/prefer-default-export
export const MemberRowBadge = styled('div')(({ usertype, hasRounderBorder, maxWidth }) => ({
  width: maxWidth ?? '100%',
  background: determineUserBadgeColor(usertype),
  padding: '3px 0',
  color: TEAL_DOLLHOUSE,
  fontSize: 10,
  textTransform: 'uppercase',
  letterSpacing: 2,
  fontWeight: 500,
  textAlign: 'center',
  borderRadius: hasRounderBorder ? 5 : 0,
}))
