import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import React from 'react'

import InviteIcon from '@public/svg/icons/invite-icon.svg'

import PopButton from '@forms/PopButton'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

const CompaniesActions = () => {
  const router = useRouter()
  const accountUser = useReactiveVar(currentAccountUser)
  if (!hasPermission.ADMIN(accountUser)) return null

  return (
    <PopButton
      onClick={() => router.push(`/admin-console/users/invite`)}
      size="small"
      btnStyle="simple"
      style={{ margin: 0 }}
      iconHeight={18}
      svgIconRight={InviteIcon}
      data-tracking-info={serializeData({
        id: 'admin-console_add-user-button_click',
      })}
    >
      invite a user
    </PopButton>
  )
}

export default CompaniesActions
