import { useReactiveVar } from '@apollo/client'
import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { companiesSearch } from '@lib/apollo/apolloCache'

const CompanyListEmpty = () => {
  const hasFilters = !!useReactiveVar(companiesSearch)

  return (
    <TableRow data-test-id="companies-list--row-no-companies">
      <TableCell style={{ border: 'none' }} colSpan={6}>
        <span>
          {hasFilters
            ? `No results found for "${companiesSearch()}"`
            : 'You have no current companies'}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default CompanyListEmpty
