export const INVOICE_STATUSES = {
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  PAID: 'PAID',
  UNCOLLECTIBLE: 'UNCOLLECTIBLE',
  VOID: 'VOID',
}

export const INVOICE_STATUSES_LABELS = {
  [INVOICE_STATUSES.DRAFT]: 'Draft',
  [INVOICE_STATUSES.OPEN]: 'Open',
  [INVOICE_STATUSES.PAID]: 'Paid',
  [INVOICE_STATUSES.UNCOLLECTIBLE]: 'Uncollectible',
  [INVOICE_STATUSES.VOID]: 'Void',
}

export const BILLING_DATE_FORMAT = 'MMM-DD-YYYY'
