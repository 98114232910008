import PropTypes from 'prop-types'
import React from 'react'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@public/svg/icons/close-thin-icon.svg'

import {
  FOURTH_GREY_BACKGROUND,
  PRIMARY_TEXT_GREY,
  PURPLE_BOP_IT,
  TEAL_DOLLHOUSE,
} from '@lib/colors'
import { FONTS } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 25px',
    wordBreak: 'break-word',
    background: FOURTH_GREY_BACKGROUND,
  },
  dialogTitleRoot: {
    fontFamily: FONTS.MERRI_WEATHER,
    margin: 0,
    fontSize: 24,
    color: TEAL_DOLLHOUSE,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    '& *:last-child': {
      fontWeight: 'normal',
      fontSize: '0.7rem',
      color: PRIMARY_TEXT_GREY,
      textTransform: 'uppercase',
    },
  },
  dialogTitleInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 25,
      height: 25,
      marginRight: 10,
    },
  },
  closeButton: {
    '&:hover': {
      background: 'transparent',
    },
    '& > span > div': {
      width: 20,
      height: 20,
    },
  },
  divider: {
    height: 3,
    background: PURPLE_BOP_IT,
  },
}))

const GembahDialogTitle = (props) => {
  const { children, onClose, disabled, icon, ...other } = props
  const classes = useStyles()

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MuiDialogTitle disableTypography classes={{ root: classes.root }} {...other}>
        <div className={classes.dialogTitleInfoWrapper}>
          {icon && <SvgLoader {...icon} />}
          <Typography classes={{ root: classes.dialogTitleRoot }}>{children}</Typography>
        </div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            disabled={disabled}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <SvgLoader {...CloseIcon} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      {children && <Divider classes={{ root: classes.divider }} />}
    </>
  )
}

GembahDialogTitle.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

GembahDialogTitle.defaultProps = {
  children: null,
}

export default GembahDialogTitle
