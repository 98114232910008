import { useQuery } from '@apollo/client'
import React from 'react'

import useRoute from '@lib/useRoute'

import { GET_ACCOUNTS } from '@graphql/users/queries'

import Loader from '@components_pop/Loader'
import usePagination from '@components_pop/Pagination/hooks'

import CompaniesView from './view'

const CompaniesContainer = () => {
  const [goTo] = useRoute()
  const [page, setPage] = usePagination({ limit: 10 })

  const { loading: isLoading, data } = useQuery(GET_ACCOUNTS)
  const { accounts: companies = [], totalCount = 0 } = data?.accounts || {}

  const handleGoToEdit = (companySlug) => {
    goTo(`/admin-console/companies/edit/${companySlug}`)
  }

  return (
    <Loader loaderElementsCount={5} isLoadingContent={isLoading} variant="company">
      <CompaniesView
        goToEdit={handleGoToEdit}
        companies={companies}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
      />
    </Loader>
  )
}

export default CompaniesContainer
