import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { Drawer, IconButton, styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CloseThinIcon from '@public/svg/icons/close-thin-icon.svg'

import { TEAL_DOLLHOUSE } from '@lib/colors'
import { FONTS, MOBILE_BREAKPOINT } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

export const DrawerFooterStyles = styled('div')(() => ({
  padding: 20,
  display: 'flex',
  justifyContent: 'space-between',
  '& > button': {
    margin: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiBackdrop-root': {
      background: 'rgba(0, 36, 48, 0.7)',
      backdropFilter: 'blur(3px)',
    },
  },
  paper: {
    width: '100%',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: '75%',
    },
  },
  drawerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawerHeader: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: 20,
      fontFamily: FONTS.MERRI_WEATHER,
      color: TEAL_DOLLHOUSE,
    },
  },
  drawerBody: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  closeBtn: {
    padding: 0,
    '& > span > div': {
      width: 13,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}))

const PopDrawer = ({ direction = 'right', title, open, onClose, children, className, actions }) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor={direction}
      open={open}
      onClose={(__, reason) => {
        if (reason === 'backdropClick') return
        onClose()
      }}
      elevation={0}
      classes={{
        root: classes.root,
        paper: clsx(classes.paper, className),
      }}
    >
      <div className={classes.drawerWrapper}>
        <div className={classes.drawerHeader}>
          <span>{title}</span>
          <IconButton
            classes={{ root: classes.closeBtn }}
            onClick={onClose}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <SvgLoader {...CloseThinIcon} />
          </IconButton>
        </div>
        <div className={classes.drawerBody}>{children}</div>
        {actions && <DrawerFooterStyles>{actions}</DrawerFooterStyles>}
      </div>
    </Drawer>
  )
}

PopDrawer.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  actions: PropTypes.object,
  children: PropTypes.object,
  className: PropTypes.any,
}

export default PopDrawer
