import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import loaders from './loaders'

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    width: '100%',
    '& > *': {
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}))

const LoaderView = ({
  loaderElementsCount = 1,
  variant = 'project',
  isLoadingContent,
  children,
}) => {
  const classes = useStyles()
  const loaderVariant = loaders[variant] || loaders[0]
  const loaderElements = Array(loaderElementsCount).fill(loaderVariant)

  const renderLoaderElements = () =>
    /* eslint-disable react/no-array-index-key */
    loaderElements.map((Element, i) => <Element index={i} key={i} />)

  return isLoadingContent ? (
    <div data-test-id="main-wrapper-loader" className={classes.loaderWrapper}>
      {renderLoaderElements()}
    </div>
  ) : (
    children
  )
}

LoaderView.propTypes = {
  loaderElementsCount: PropTypes.number,
  variant: PropTypes.string,
  isLoadingContent: PropTypes.bool,
  children: PropTypes.any,
}

export default LoaderView
