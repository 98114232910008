import PropTypes from 'prop-types'
import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'

const useStyles = makeStyles((theme) => ({
  base: ({ dimensions }) => ({
    display: 'inline-flex',
    height: theme.spacing(dimensions),
    width: theme.spacing(dimensions),
    fontSize: dimensions >= 4 ? 20 : theme.spacing(dimensions - 1.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),
}))

const UserLetterAvatar = ({ firstName = '', lastName = '', dimensions = 4, ...props }) => {
  const userInitials = (firstName?.[0] || '') + (lastName?.[0] || '')
  const classes = useStyles({ dimensions, ...props })

  const avatarContent = userInitials || (
    <SvgIcon>
      <PersonIcon />
    </SvgIcon>
  )

  return (
    <Avatar classes={{ root: classes.base }} {...props}>
      {avatarContent}
    </Avatar>
  )
}

UserLetterAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  dimensions: PropTypes.number,
}

UserLetterAvatar.defaultProps = {
  firstName: '',
  lastName: '',
  dimensions: 4,
}

export default UserLetterAvatar
