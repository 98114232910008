import PropTypes from 'prop-types'
import React from 'react'

import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    // background: 'rgba(255, 255, 255, 0.5)',
  },
  linearProgress: {
    marginTop: 60,
  },
}))

const FormDialogSubmittingOverlay = ({ open }) => {
  const classes = useStyles()

  if (open) {
    return (
      <div className={classes.root}>
        <LinearProgress className={classes.linearProgress} />
      </div>
    )
  }

  return null
}

FormDialogSubmittingOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default FormDialogSubmittingOverlay
