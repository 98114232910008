import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { isInviteMembersModalOpen } from '@lib/apollo/apolloCache'

import InviteMembersModal from './InviteMembersModal'
import MembersView from './view'

const MembersContainer = () => {
  const isModalOpen = useReactiveVar(isInviteMembersModalOpen)
  const onCloseModal = () => isInviteMembersModalOpen(false)

  return (
    <>
      <MembersView />
      {isModalOpen && <InviteMembersModal onCloseModal={onCloseModal} />}
    </>
  )
}

export default MembersContainer
