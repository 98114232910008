import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
}))

const PopTextField = React.forwardRef((props, ref) => {
  const {
    error,
    label,
    id,
    helperText,
    FormLabelProps,
    fullWidth,
    className,
    disableHelperText,
    startIcon,
    endIcon,
    'data-test-id': dataTestId,
    multiline,
    required,
    type,
    ...InputProps
  } = props

  const classes = useStyles()
  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && (
        <FormLabel className={classes.label} htmlFor={id} error={error} {...FormLabelProps}>
          {label}
          {!!required && <span>*</span>}
        </FormLabel>
      )}
      <Input
        startAdornment={startIcon && <div className={classes.iconWrapper}>{startIcon}</div>}
        endAdornment={
          endIcon && (
            <div className={clsx(classes.iconWrapper, classes.endIconWrapper)}>{endIcon}</div>
          )
        }
        className={multiline ? classes.multilineInput : classes.input}
        multiline={multiline}
        inputRef={ref}
        id={id}
        error={error}
        {...InputProps}
        classes={{ root: classes.root }}
        inputProps={{ 'data-test-id': dataTestId, type }}
        disableUnderline
      />
      {!disableHelperText && error && (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
})

PopTextField.defaultProps = {
  label: '',
  error: false,
  FormLabelProps: {},
  disableHelperText: false,
  multiline: false,
}

PopTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  disableHelperText: PropTypes.bool,
  FormLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  'data-test-id': PropTypes.string,
  multiline: PropTypes.bool,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  required: PropTypes.bool,
  type: PropTypes.string,
  // Essentially most props that MUI's TextField supports
}

export default PopTextField
