import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { COMMON_WHITE, PURPLE_BOP_IT, PURPLE_POLLYPOCKET } from '@lib/colors'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = (btnWidth, iconHeight) =>
  makeStyles((theme) => ({
    button: {
      borderRadius: 10,
      textTransform: 'none',
      fontWeight: 700,
      whiteSpace: 'nowrap',
    },
    filled: {
      ...theme.palette.pop,
    },
    simple: {
      color: PURPLE_BOP_IT,
      background: 'rgba(222, 219, 217, 0.4)',
      '&:hover': {
        background: 'rgba(222, 219, 217, 0.6)',
      },
      '&:disabled': {
        background: 'rgba(222, 219, 217, 0.4)',
        color: PURPLE_POLLYPOCKET,
      },
    },
    big: {
      fontSize: 18,
      height: '3rem',
    },
    round: {
      ...theme.palette.pop,
      borderRadius: '50%',
      width: btnWidth || 'inherit',
      height: btnWidth || 'inherit',
    },
    noGradient: {
      color: COMMON_WHITE,
    },
    small: {
      fontSize: 14,
    },
    square: {
      height: 40,
      minWidth: 0,
      padding: 0,
      width: 40,
      '& > span > div': {
        margin: 0,
        '& > div': {
          width: 24,
        },
      },
    },
    iconSvg: {
      '& > div': {
        width: 15,
        height: iconHeight ?? 28,
      },
    },
    iconSvgLeft: {
      marginRight: 10,
    },
    iconsSvgRight: {
      marginLeft: 10,
    },
  }))

const PopButton = ({
  size = 'big',
  btnStyle = 'filled',
  // applies only to round
  btnWidth,
  iconHeight,
  type,
  children,
  loading,
  className,
  svgIconRight,
  svgIconLeft,
  ...btnProps
}) => {
  const classes = useStyles(btnWidth, iconHeight)()
  const btnClasses = clsx(className, classes.button, classes[size], classes[btnStyle])

  return (
    <Button
      disableElevation
      variant="contained"
      type={type}
      classes={{ root: btnClasses }}
      {...btnProps}
    >
      {!!svgIconLeft && (
        <SvgLoader
          {...svgIconLeft}
          isLoading={loading}
          className={clsx(classes.iconSvg, classes.iconSvgLeft)}
        />
      )}
      {children}
      {!!svgIconRight && (
        <SvgLoader
          {...svgIconRight}
          isLoading={loading}
          className={clsx(classes.iconSvg, classes.iconsSvgRight)}
        />
      )}
    </Button>
  )
}

PopButton.propTypes = {
  btnWidth: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  iconHeight: PropTypes.number,
  svgIconRight: PropTypes.object,
  svgIconLeft: PropTypes.object,
  btnStyle: PropTypes.string,
  className: PropTypes.string,
}

export default PopButton
