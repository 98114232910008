import { useMutation, useLazyQuery, useReactiveVar, useQuery } from '@apollo/client'
import Image from 'next/image'
import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  currentAccount,
  currentAccountOwner,
  currentAccountUser,
  currentAccountUsers,
  currentUser,
  featureFlags,
} from '@lib/apollo/apolloCache'
import { DEPLOYMENT_VERSION_POLL_INTERVAL } from '@lib/apollo/apolloConfig'
import tracking from '@lib/tracking'
import useRoute from '@lib/useRoute'
import { ACCOUNT_USER_STATUS } from '@lib/userAuth'

import { SET_TIME_ZONE } from '@graphql/user/mutators'
import {
  GET_AUTH_USER,
  GET_DEPLOYMENT_VERSION,
  GET_DEPLOYMENT_VERSION_MISMATCH,
} from '@graphql/user/queries'

import { getUsersByRole } from '@components_pop/AdminConsole/utils'
import { TOAST_TYPES } from '@components_pop/Toast'
import UserContext from '@components_pop/users/UserContext'

import useToast from '@hooks/useToast'

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function withAuth(PageComponent, accountType) {
  const WithAuth = ({ ...pageProps }) => {
    const classes = useStyles()
    const cacheUser = useReactiveVar(currentUser)
    const cacheAccountUser = useReactiveVar(currentAccountUser)
    const [goTo, , pathname] = useRoute()
    const { addToast } = useToast()

    const [sendMismatchedDeploymentVersion] = useLazyQuery(GET_DEPLOYMENT_VERSION_MISMATCH, {
      variables: { clientVersion: process.env.DEPLOYMENT_VERSION },
    })

    const [setTimeZone] = useMutation(SET_TIME_ZONE, {
      variables: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      refetchQueries: [{ query: GET_AUTH_USER }],
    })

    useQuery(GET_DEPLOYMENT_VERSION, {
      pollInterval: DEPLOYMENT_VERSION_POLL_INTERVAL,
      notifyOnNetworkStatusChange: true,
      onCompleted(versionData) {
        if (versionData?.deploymentVersion?.currentVersion !== 'UNKNOWN') {
          const actualVersion = versionData?.deploymentVersion?.currentVersion
          const clientVersion = process.env.DEPLOYMENT_VERSION

          if (actualVersion !== clientVersion) {
            sendMismatchedDeploymentVersion()
            setTimeout(() => {
              addToast({
                customId: 'refresh-reminder',
                noAutoClose: true,
                message: () => (
                  <>
                    <div>A new version of the platform is available.</div>
                    <div>
                      Click{' '}
                      <b style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>
                        here
                      </b>{' '}
                      to refresh.
                    </div>
                  </>
                ),
                type: TOAST_TYPES.INFO,
              })
            }, 1000 * 600) // 5 minutes timeout
          }
        }
      },
    })

    const [getMe, { called, loading, error }] = useLazyQuery(GET_AUTH_USER, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted(queryData) {
        const userData = { ...queryData.me }

        const accountUser = userData?.userAccounts?.[0]
        const account = userData?.accounts?.[0]
        const processedFlags = userData.flags.reduce(
          (soFar, flag) => ({ ...soFar, [flag.name]: flag.active }),
          {}
        )
        userData.uiUserType = account?.type
        userData.uiUserRole = accountUser?.roles?.map(({ role }) => role)

        const status = accountUser?.status
        if (!userData.timeZone) setTimeZone()

        featureFlags({ ...processedFlags })
        currentUser({ ...userData, status })
        currentAccountUser({ ...accountUser })
        currentAccount({ ...account })
        const accountOwner = getUsersByRole.OWNER(account)[0] ?? {}
        currentAccountOwner({ ...accountOwner })
        currentAccountUsers(account?.accountUsers ?? [])
      },
    })

    useEffect(() => {
      if (getMe && !called) {
        getMe()
      }
    }, [getMe, called])

    useEffect(() => {
      if (!cacheUser) return

      if (!tracking.userIdentified) {
        tracking.identify()
      }
    }, [cacheUser])

    if (!cacheUser && loading) {
      return (
        <div className={classes.loader}>
          <Image src="/Gembah-Loading.gif" alt="loading" width="100" height="100" />
        </div>
      )
    }

    if (error || !cacheUser) {
      // prevents UI flicker when user has not completed the contractor onboarding
      return null
    }

    const invitePending = cacheAccountUser?.status === ACCOUNT_USER_STATUS.INVITE_PENDING
    if (invitePending && pathname !== '/account-creation') {
      goTo('/account-creation')
      return null
    }

    const hasAccess = accountType ? cacheAccountUser?.account?.type === accountType : true
    if (!hasAccess && pathname !== '/') {
      goTo('')
      return null
    }

    return (
      cacheUser && (
        <UserContext.Provider value={cacheUser}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <PageComponent {...pageProps} />
        </UserContext.Provider>
      )
    )
  }

  return WithAuth
}

export default withAuth
