import { useMutation, useReactiveVar } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'

import InfoIcon from '@public/svg/icons/info-icon.svg'
import InviteIcon from '@public/svg/icons/invite-icon.svg'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'
import PopTextField from '@forms/PopTextField'

import { currentAccount } from '@lib/apollo/apolloCache'
import { GRAY_NICKELARCADE, GREEN_TMNT } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'
import { USER_ROLES } from '@lib/userAuth'

import { CREATE_ACCOUNT_USER } from '@graphql/user/mutators'
import { GET_AUTH_USER } from '@graphql/user/queries'

import SvgLoader from '@components_pop/SvgLoader'
import { TOAST_TYPES } from '@components_pop/Toast'
import GembahDialogTitle from '@components_pop/dialog/GembahDialogTitle'

import useToast from '@hooks/useToast'

const useStyles = makeStyles((theme) => ({
  hintText: {
    fontSize: 12,
    color: GRAY_NICKELARCADE,
  },
  textBoxWrapper: {
    padding: 0,
    margin: '20px 0 40px',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: '0 40px',
    },
  },
  infoText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    fontWeight: 600,
    color: GREEN_TMNT,
    '& > div': {
      marginRight: 5,
      width: 12,
      height: 12,
    },
  },
}))

const InviteMembersModal = ({ onCloseModal }) => {
  const account = useReactiveVar(currentAccount)

  const classes = useStyles()
  const { addToast } = useToast()

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [createAccountUser] = useMutation(CREATE_ACCOUNT_USER, {
    refetchQueries: [{ query: GET_AUTH_USER }],
  })

  const schema = Yup.object().shape({
    emailAddresses: Yup.string()
      .trim()
      .required('At least one email is required')
      .test({
        name: 'test-valid-emails',
        test(emailsString) {
          const emails = emailsString.split(',')

          return (
            emails.every((email) => {
              return Yup.string().email().isValidSync(email.trim())
            }) ||
            // eslint-disable-next-line react/no-this-in-sfc
            this.createError({
              message: 'Please enter valid emails, separated by comma',
            })
          )
        },
      }),
  })

  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      emailAddresses: '',
    },
    resolver: yupResolver(schema),
  })

  const { isValid } = formState

  const handleSendInvites = ({ emailAddresses }) => {
    setIsSubmitted(true)

    createAccountUser({
      variables: {
        accountId: account.id,
        invitees: emailAddresses.split(',').map((emailAddress) => {
          return {
            email: emailAddress.trim(),
            roleInput: {
              role: USER_ROLES.MEMBER,
            },
          }
        }),
      },
    })
      .then((res) => {
        if (res.errors) {
          addToast({
            message: 'Could not send invites',
            type: TOAST_TYPES.ERROR,
          })
          return
        }

        onCloseModal()
      })
      .finally(() => setIsSubmitted(false))
  }

  return (
    <RootDialog
      open
      fullWidth
      maxWidth="md"
      onClose={(__, reason) => {
        if (reason === 'backdropClick') return
        onCloseModal()
      }}
      data-test-id="admin-console--invite-members-dialog"
    >
      <GembahDialogTitle onClose={onCloseModal} disabled={isSubmitted} icon={InviteIcon}>
        Invite Members
      </GembahDialogTitle>
      <RootDialogContent>
        <div className={classes.hintText}>You can invite members to join your organization.</div>
        <div className={classes.textBoxWrapper}>
          <PopTextField
            label="Email Addresses"
            data-test-id="admin-console--invite-members-textarea"
            name="emailAddresses"
            error={!!errors.emailAddresses}
            helperText={errors.emailAddresses?.message}
            data-tracking-info={serializeData({
              id: 'admin-console_invite-members-textarea_click',
            })}
            placeholder="Email, comma separated"
            ref={register}
            rows={8}
            disableHelperText={!errors.emailAddresses}
            multiline
            fullWidth
            required
          />
          <div className={classes.infoText}>
            <SvgLoader {...InfoIcon} />
            Your teammates will receive an email that gives them access to your organization.
          </div>
        </div>
      </RootDialogContent>
      <RootDialogActions>
        <span />
        <PopButton
          data-test-id="admin-console--invite-members-save-btn"
          loading={isSubmitted}
          disabled={!isValid || isSubmitted}
          size="small"
          onClick={handleSubmit(handleSendInvites)}
          data-tracking-info={serializeData({
            id: 'admin-console_invite-members-save-button_click',
          })}
        >
          {isSubmitted ? 'sending invites...' : 'send invites'}
        </PopButton>
      </RootDialogActions>
    </RootDialog>
  )
}

InviteMembersModal.propTypes = {
  onCloseModal: PropTypes.func,
}

export default InviteMembersModal
