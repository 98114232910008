import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import GembahTableView from './view'

const GembahTableContainer = ({
  emptyState,
  headers,
  listItems,
  listItemsMap = [],
  orderByVar,
  paginationProps,
  tableClassNames = {},
  tableName,
  WithExpansionContent,
  expansionArrowClass,
  onSortClick,
  ...rest
}) => {
  const [sorting, setSorting] = useState(orderByVar())
  const [isLoadingSort, setIsLoadingSort] = useState(false)

  useEffect(() => {
    setSorting(orderByVar())
  }, [orderByVar()])

  const getSortFunc = (fieldName) => {
    setIsLoadingSort(true)
    let newSortOrder = fieldName
    if (sorting === fieldName) {
      newSortOrder = `-${fieldName}`
    }

    orderByVar(newSortOrder)
    setSorting(newSortOrder)
    onSortClick()
  }

  useEffect(() => {
    setIsLoadingSort(false)
  }, [listItems])

  return (
    <GembahTableView
      onSortClick={getSortFunc}
      tableName={tableName}
      headers={headers}
      listItems={listItems}
      listItemsMap={listItemsMap}
      columnSorting={sorting}
      isLoadingSort={isLoadingSort}
      emptyState={emptyState}
      tableClassNames={tableClassNames}
      paginationProps={paginationProps}
      WithExpansionContent={WithExpansionContent}
      expansionArrowClass={expansionArrowClass}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  )
}

GembahTableContainer.propTypes = {
  orderByVar: PropTypes.func,
  tableName: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyState: PropTypes.element.isRequired,
  listItemsMap: PropTypes.func,
  tableClassNames: PropTypes.object,
  paginationProps: PropTypes.object,
  WithExpansionContent: PropTypes.any,
  expansionArrowClass: PropTypes.any,
  onSortClick: PropTypes.func,
}

GembahTableContainer.defaultProps = {
  listItemsMap: () => {},
  orderByVar: () => {},
  tableClassNames: {},
  paginationProps: null,
  onSortClick: () => {},
}

export default GembahTableContainer
