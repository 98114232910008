/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import React from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import CheckIcon from '@public/svg/icons/check-icon.svg'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'

import SvgLoader from '@components_pop/SvgLoader'

import GembahDialogTitle from './GembahDialogTitle'

const useStyles = makeStyles(() => ({
  dialogContent: {
    alignItems: 'center',
  },
  iconRoot: {
    width: 60,
  },
}))

const GembahFormSuccessDialog = ({
  onClose,
  successText,
  buttonLabel,
  onButtonClick,
  ...dialogProps
}) => {
  const classes = useStyles()

  return (
    <RootDialog open onClose={onClose} disableBackdropClick {...dialogProps}>
      <GembahDialogTitle onClose={onClose} data-test-id="dialog-title" />
      <RootDialogContent className={classes.dialogContent} data-test-id="dialog-content">
        <SvgLoader {...CheckIcon} className={classes.iconRoot} data-test-id="dialog-icon" />
        <Typography variant="h2" data-test-id="dialog-text">
          {successText}
        </Typography>
      </RootDialogContent>
      <RootDialogActions>
        <PopButton
          btnStyle="simple"
          size="small"
          onClick={onButtonClick}
          data-test-id="dialog-button"
        >
          {buttonLabel}
        </PopButton>
      </RootDialogActions>
    </RootDialog>
  )
}

GembahFormSuccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  successText: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

export default GembahFormSuccessDialog
