import { useReactiveVar } from '@apollo/client'

import { appErrors, cachedToasts } from '@lib/apollo/apolloCache'

import { DEFAULT_TOAST_CLOSE, MAX_TOASTS, TOAST_TYPES } from '@components_pop/Toast'

const useToast = () => {
  const toasts = useReactiveVar(cachedToasts)

  const deleteToast = (id, onClose) => {
    const currentToasts = cachedToasts()
    const toastToDeleteIndex = currentToasts.findIndex((toast) => toast.id === id)
    const { autoClose } = currentToasts[toastToDeleteIndex]

    currentToasts.splice(toastToDeleteIndex, 1)
    cachedToasts([...currentToasts])

    if (autoClose !== null) clearTimeout(autoClose)
    if (onClose) onClose()
  }

  const getLatestAppError = () => (appErrors().length > 0 ? appErrors().slice(-1)[0] : '')

  const constructToast = ({
    customId,
    onClose,
    message = getLatestAppError(),
    noManualClose = false,
    noAutoClose = false,
    autoCloseTime = DEFAULT_TOAST_CLOSE,
    type = TOAST_TYPES.NEUTRAL,
  }) => {
    const id = customId || Date.now()

    return {
      id,
      message,
      type,
      noManualClose,
      onClose,
      autoClose: noAutoClose ? null : setTimeout(deleteToast, autoCloseTime, id, onClose),
    }
  }

  const hasSameMessage = (newToast) =>
    cachedToasts().find(
      (toast) => toast.message === newToast.message || toast.id === newToast.customId
    )

  const removeExtraToast = (allToasts) => {
    const newToast = allToasts.pop()
    clearTimeout(newToast.autoClose)

    return allToasts
  }

  const addToast = (toast) => {
    if (hasSameMessage(toast)) return

    const currentToasts = cachedToasts()

    const oldToasts =
      currentToasts.length === MAX_TOASTS ? removeExtraToast(currentToasts) : currentToasts
    const newToast = constructToast(toast)
    cachedToasts([newToast, ...oldToasts])
  }

  const clearToasts = () => cachedToasts([])

  return { toasts, addToast, deleteToast, clearToasts, getLatestAppError }
}

export default useToast
