import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CadIcon from '@public/svg/icons/mimetypes/cad.svg'
import CsvIcon from '@public/svg/icons/mimetypes/csv.svg'
import ExcelIcon from '@public/svg/icons/mimetypes/excel.svg'
import GenericIcon from '@public/svg/icons/mimetypes/generic.svg'
import GoogleDriveIcon from '@public/svg/icons/mimetypes/google-drive.svg'
import ImageIcon from '@public/svg/icons/mimetypes/image.svg'
import PdfIcon from '@public/svg/icons/mimetypes/pdf.svg'
import TxtIcon from '@public/svg/icons/mimetypes/txt.svg'
import WordIcon from '@public/svg/icons/mimetypes/word.svg'

import { MIME_TYPES_FOR_ICONS } from '@lib/constants'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles(() => ({
  root: {
    width: 55,
  },
}))

const MediaIcon = ({ mimetype, className }) => {
  const classes = useStyles()

  const finalClassName = clsx(classes.root, className)

  switch (mimetype) {
    case MIME_TYPES_FOR_ICONS.CAD_DRAWING:
    case MIME_TYPES_FOR_ICONS.CAD_INTERCHANGE:
    case MIME_TYPES_FOR_ICONS.CAD_WEB_FORMAT:
      return <SvgLoader {...CadIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.CSV:
      return <SvgLoader {...CsvIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.DOCX:
      return <SvgLoader {...WordIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.JPEG:
    case MIME_TYPES_FOR_ICONS.PNG:
      return <SvgLoader {...ImageIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.PDF:
      return <SvgLoader {...PdfIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.TXT:
      return <SvgLoader {...TxtIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.GOOGLE_DRIVE:
      return <SvgLoader {...GoogleDriveIcon} className={finalClassName} />

    case MIME_TYPES_FOR_ICONS.XLS:
    case MIME_TYPES_FOR_ICONS.XLSX:
      return <SvgLoader {...ExcelIcon} className={finalClassName} />

    default:
      return <SvgLoader {...GenericIcon} className={finalClassName} />
  }
}

MediaIcon.propTypes = {
  mimetype: PropTypes.string,
  className: PropTypes.string,
}

MediaIcon.defaultProps = {
  className: '',
}

export default memo(MediaIcon)
