import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { RootDialog, RootDialogActions, RootDialogContent } from '@dialog/RootDialog'

import PopButton from '@forms/PopButton'
import PopComboField from '@forms/PopComboField'

import { BULK_REASSIGN_PROJECTS_TO_STAFF_USER } from '@graphql/users/mutators'
import { GET_GEMBAH_USERS } from '@graphql/users/queries'

import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import FormDialogSubmittingOverlay from './FormDialogSubmittingOverlay'
import GembahDialogTitle from './GembahDialogTitle'

const GembahReassignProjectsModal = ({
  userFrom,
  onClose,
  isReassigning = true,
  onSuccessCallback,
}) => {
  const [toStaffUser, setToStaffUser] = useState()
  const [submitting, setSubmitting] = useState(false)

  const { addToast } = useToast()
  const { firstName, lastName, userId: fromStaffUserId } = userFrom || {}
  const reassignSubtitle = `from ${firstName} ${lastName}`

  const [reassignBulkProjects] = useMutation(BULK_REASSIGN_PROJECTS_TO_STAFF_USER)
  const { data: availableGembahUsers } = useQuery(GET_GEMBAH_USERS)

  const userOptions =
    availableGembahUsers?.gembahUsers
      ?.filter((user) => user?.id !== userFrom?.userId)
      ?.map(({ user, id }) => ({
        text: user?.fullName,
        value: id,
      })) ?? []

  const handleMoveProjects = () => {
    setSubmitting(true)
    const { value: toStaffUserId, text: toUserName } = toStaffUser

    reassignBulkProjects({
      variables: {
        fromStaffUserId,
        toStaffUserId,
      },
    })
      .then((res) => {
        if (res.errors) {
          addToast({
            message: `Could not reassign projects to ${toUserName}`,
            type: TOAST_TYPES.ERROR,
          })
          return
        }

        addToast({
          message: `Succesfully reassigned projects to ${toUserName}`,
          type: TOAST_TYPES.SUCCESS,
        })
        // the linter does not understand this yet
        /* eslint-disable no-unused-expressions */
        onSuccessCallback?.()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <RootDialog
      open
      fullWidth
      maxWidth="xs"
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return
        onClose()
      }}
      data-test-id="reassign-projects-modal"
    >
      <FormDialogSubmittingOverlay open={submitting} />
      <GembahDialogTitle onClose={onClose}>
        <span>Move Projects</span>
        <span>{reassignSubtitle}</span>
      </GembahDialogTitle>
      <RootDialogContent>
        <PopComboField
          data-test-id="reassing-projects-select"
          label="Reassign All Projects to"
          placeholder="Select Gembah User"
          onChange={(_, option) => setToStaffUser(option)}
          noOptionsText="No available Gembah Users found."
          options={userOptions}
          fullWidth
        />
      </RootDialogContent>
      <RootDialogActions>
        {onClose && (
          <PopButton
            btnStyle="simple"
            size="small"
            onClick={onClose}
            data-test-id="dialog-reassign-confirm-close-button"
          >
            Cancel
          </PopButton>
        )}
        <PopButton
          disabled={!toStaffUser}
          size="small"
          onClick={handleMoveProjects}
          data-test-id="dialog-reassign-confirm-accept-button"
          loading={submitting}
        >
          {isReassigning ? 'Reassign' : 'Assign'}
        </PopButton>
      </RootDialogActions>
    </RootDialog>
  )
}

GembahReassignProjectsModal.propTypes = {
  onClose: PropTypes.func,
  userFrom: PropTypes.object,
  isReassigning: PropTypes.bool,
  onSuccessCallback: PropTypes.func,
}

export default GembahReassignProjectsModal
