import React from 'react'

import OfficeIcon from '@public/svg/icons/company.svg'
import CreatorIcon from '@public/svg/icons/creator-icon.svg'
import ExpertIcon from '@public/svg/icons/expert-icon.svg'
import FactoryIcon from '@public/svg/icons/factory-icon.svg'
import GembahIcon from '@public/svg/icons/gembah-icon.svg'

import { ACCOUNT_TYPES } from '@lib/userAuth'

import ExternalUsersfilters from '@components_pop/AdminConsole/Internal/Users/ExternalUsersFilters'
import InternalUsersFilters from '@components_pop/AdminConsole/Internal/Users/InternalUsersFilters'
import Users from '@components_pop/AdminConsole/Internal/Users/Users'
import UsersActions from '@components_pop/AdminConsole/Internal/UsersActions'

import Companies from './Companies'
import CompaniesActions from './Companies/CompaniesActions'
import CompaniesFilters from './Companies/CompaniesFilters'

export const ROUTES_NAMES = {
  COMPANIES: 'companies',
  CREATORS: 'creator',
  EXPERTS: 'expert',
  FACTORIES: 'factory',
  GEMBAH: 'gembah',
}

export const ROUTES = {
  [ROUTES_NAMES.COMPANIES]: {
    name: 'Companies',
    title: 'Companies',
    url: 'companies',
    component: Companies,
    icon: OfficeIcon,
    action: CompaniesActions,
    filters: CompaniesFilters,
  },
  [ROUTES_NAMES.CREATORS]: {
    name: 'Creators',
    title: 'Creators',
    url: 'creator',
    textDivider: 'Users',
    component: () => <Users userType={ACCOUNT_TYPES.CREATOR} />,
    icon: CreatorIcon,
    action: UsersActions,
    filters: () => <ExternalUsersfilters userType={ACCOUNT_TYPES.CREATOR} />,
  },
  [ROUTES_NAMES.EXPERTS]: {
    name: 'Experts',
    title: 'Experts',
    url: 'expert',
    component: () => <Users userType={ACCOUNT_TYPES.EXPERT} />,
    icon: ExpertIcon,
    action: UsersActions,
    filters: () => <ExternalUsersfilters userType={ACCOUNT_TYPES.EXPERT} />,
  },
  [ROUTES_NAMES.FACTORIES]: {
    name: 'Factory',
    title: 'Factory',
    url: 'factory',
    component: () => <Users userType={ACCOUNT_TYPES.FACTORY} />,
    icon: FactoryIcon,
    action: UsersActions,
    filters: () => <ExternalUsersfilters userType={ACCOUNT_TYPES.FACTORY} />,
  },
  [ROUTES_NAMES.GEMBAH]: {
    name: 'Gembah',
    title: 'Gembah',
    url: 'gembah',
    component: () => <Users userType={ACCOUNT_TYPES.GEMBAH} />,
    icon: GembahIcon,
    action: UsersActions,
    filters: InternalUsersFilters,
  },
}
