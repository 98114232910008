import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import GlobeIcon from '@public/svg/icons/globe-icon.svg'
import WebIcon from '@public/svg/icons/website-icon.svg'

import CancelButton from '@forms/CancelButton'
import PopButton from '@forms/PopButton'
import PopComboField from '@forms/PopComboField'
import PopPhoneField from '@forms/PopPhoneField'
import PopTextField from '@forms/PopTextField'

import { COUNTRIES, COUNTRIES_OPTIONS } from '@lib/constants'
import { getCountryImageUrl } from '@lib/dataUtils'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formWrapper: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    '& > div': {
      maxWidth: 500,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    marginBottom: 60,
    '& > button': {
      margin: 0,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginBottom: 0,
    },
  },
}))
const CompanyView = ({ form, isSubmitting, onResetForm, onFormSubmit, hasPermission }) => {
  const classes = useStyles()
  const { register, control, errors, watch, formState, handleSubmit, setValue } = form
  const { isDesktop } = useBreakpoint()
  const btnSize = isDesktop ? 'big' : 'small'

  const { isValid } = formState

  const { location: defaultLocation } = watch(['location'])

  return (
    <div className={classes.root}>
      <div className={classes.formWrapper}>
        <div>
          <PopTextField
            disabled={!hasPermission}
            id="admin-console-company-name"
            name="companyName"
            data-test-id="admin-console-company-form--company-name"
            label="Company Name"
            ref={register}
            error={!!errors.companyName}
            helperText={errors.companyName?.message}
            data-tracking-info={serializeData({
              id: 'admin-console_company-name-input_click',
            })}
            fullWidth
          />
          <PopTextField
            disabled={!hasPermission}
            id="admin-console-url"
            name="url"
            data-test-id="admin-console-company-form--company-url"
            label="Website URL"
            startIcon={<SvgLoader {...WebIcon} />}
            ref={register}
            error={!!errors.url}
            helperText={errors.url?.message}
            placeholder="enter a valid website URL"
            data-tracking-info={serializeData({
              id: 'admin-console_company-url-input_click',
            })}
            fullWidth
          />
          <PopPhoneField
            disabled={!hasPermission}
            label="Phone Number"
            id="admin-console-phone"
            data-test-id="admin-console-company-form--company-phone"
            name="phone"
            control={control}
            error={!!errors.phone}
            helperText={errors.phone?.message}
            placeholder="enter a valid phone number"
            data-tracking-info={serializeData({
              id: 'admin-console_company-phone-input_click',
            })}
            fullWidth
          />
          <PopComboField
            fullWidth
            name="location"
            label="Location"
            inputIcon={GlobeIcon}
            options={COUNTRIES_OPTIONS}
            value={
              defaultLocation ? { text: COUNTRIES[defaultLocation], value: defaultLocation } : null
            }
            getOptionLabel={(option) => option.text || ''}
            getOptionSelected={(opt, selected) => opt.value === selected.value}
            onChange={(_e, option, reason) => {
              if (!['select-option', 'clear'].includes(reason)) {
                return
              }

              if (reason === 'clear') {
                setValue('location', '')
                return
              }

              setValue('location', option.value, { shouldValidate: true })
            }}
            error={!!errors.location}
            helperText={errors.location?.message}
            placeholder="select a country..."
            renderOption={(option, props) => {
              return (
                <div className={classes.countryOption} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={getCountryImageUrl(option.value)}
                    alt={option.value}
                  />
                  {option.text}
                </div>
              )
            }}
          />
        </div>
      </div>

      {hasPermission && (
        <div className={classes.actions}>
          <CancelButton
            data-test-id="admin-console-company-form--company-cancel-btn"
            onClick={onResetForm}
            disabled={isSubmitting}
            size={btnSize}
            data-tracking-info={serializeData({
              id: 'admin-console_company-cancel-button_click',
            })}
          >
            discard changes
          </CancelButton>

          <PopButton
            data-test-id="admin-console-company-form--company-save-btn"
            type="submit"
            size={btnSize}
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onFormSubmit)}
            data-tracking-info={serializeData({
              id: 'admin-console_company-save-button_click',
            })}
          >
            update company
          </PopButton>
        </div>
      )}
    </div>
  )
}

CompanyView.propTypes = {
  form: PropTypes.object,
  isSubmitting: PropTypes.bool,
  hasPermission: PropTypes.bool,
  onResetForm: PropTypes.func,
  onFormSubmit: PropTypes.func,
}

export default CompanyView
