import { gql } from '@apollo/client'

export const EDIT_CONTRACTOR = gql`
  mutation EditContractor($userId: ID!, $userData: ContractorInput!) {
    editContractor(userId: $userId, userData: $userData) {
      user {
        id
        email
        firstName
        lastName
        timeZone
        isActive
        profilePicture {
          url
          filename
        }
        contractor {
          title
          phone
          website
          primaryAddress {
            country
            locality
          }
          defaultHourlyRate {
            amount
            currency
          }
        }
      }
    }
  }
`

export const ADD_SPECIALITY = gql`
  mutation AddSpeciality($userId: ID!, $specialityData: SpecialityInputType!) {
    addSpeciality(userId: $userId, specialityData: $specialityData) {
      user {
        id
        contractor {
          specialities {
            name
            type
          }
        }
      }
    }
  }
`

export const DELETE_SPECIALITY = gql`
  mutation DeleteSpeciality($userId: ID!, $specialityData: SpecialityInputType!) {
    deleteSpeciality(userId: $userId, specialityData: $specialityData) {
      user {
        id
        contractor {
          specialities {
            name
            type
          }
        }
      }
    }
  }
`

export const ADD_PORTFOLIO_ITEM = gql`
  mutation AddPortfolioItem($userId: ID!, $portfolioItem: PortfolioItemInputType!) {
    addPortfolioItem(userId: $userId, portfolioItemData: $portfolioItem) {
      user {
        id
        contractor {
          portfolioItems {
            label
            link
          }
        }
      }
    }
  }
`

export const DELETE_PORTFOLIO_ITEM = gql`
  mutation DeletePortfolioItem($id: ID!) {
    deletePortfolioItem(portfolioItemId: $id) {
      user {
        id
        contractor {
          portfolioItems {
            label
            link
          }
        }
      }
    }
  }
`

export const UPDATE_ACCOUNT_PROFILE = gql`
  mutation UpdateAccountProfile($accountId: ID!, $profileInfo: AccountProfileInput!) {
    updateAccountProfile(accountId: $accountId, profileInfo: $profileInfo) {
      accountProfile {
        id
      }
    }
  }
`
