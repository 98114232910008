import { useReactiveVar } from '@apollo/client'
import React from 'react'

import InviteIcon from '@public/svg/icons/invite-icon.svg'

import PopButton from '@forms/PopButton'

import { currentAccountUser, isInviteMembersModalOpen } from '@lib/apollo/apolloCache'
import { serializeData } from '@lib/tracking'
import { hasPermission } from '@lib/userAuth'

const MembersActions = () => {
  const accountUser = useReactiveVar(currentAccountUser)

  if (!hasPermission.ADMIN(accountUser)) return null

  return (
    <PopButton
      onClick={() => isInviteMembersModalOpen(true)}
      size="small"
      btnStyle="simple"
      style={{ margin: 0 }}
      iconHeight={20}
      svgIconRight={InviteIcon}
      data-tracking-info={serializeData({
        id: 'admin-console_invite-members-button_click',
      })}
    >
      invite members
    </PopButton>
  )
}

export default MembersActions
