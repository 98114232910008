import { useRouter } from 'next/router'

const useRoute = () => {
  const { route, push, back, pathname } = useRouter()

  const goTo = (path = '') => {
    if (!path) {
      push('/')
    } else {
      push(route.includes('pop') ? `/pop${path}` : path)
    }
  }

  return [goTo, back, pathname]
}

export default useRoute
