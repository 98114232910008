import React from 'react'

const UserContext = React.createContext({
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  contractor: null,
  customer: null,
  staffUser: null,
  uiUserType: null, // see USER_TYPES constants
  uiUserRole: null,
})

export default UserContext
