import { createTheme } from '@material-ui/core/styles'

import {
  FOURTH_GREY,
  PRIMARY_GREY,
  PRIMARY_ICON_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  THIRD_TEXT_COLOR,
  SECONDARY_COLOR,
  SECONDARY_GREY,
  SECONDARY_GREY_BACKGROUND,
  TERTIARY_COLOR_LIGHT,
  TERTIARY_COLOR_MAIN,
  TERTIARY_COLOR_DARK,
  THIRD_GREY,
  BOX_BORDER_COLOR,
  FOURTH_GREY_BACKGROUND,
  COMMON_WHITE,
  PRIMARY_BLACK_BACKGROUND,
  NPI_GREY,
  PURPLE_MEGATRON,
  TEAL_ROBOTS,
  GRAY_NES,
  GRAY_WII,
  TEAL_DOLLHOUSE,
  RED_SURPRISE,
  RED_STRAWBERRY_SHORT_CAKE,
  TEAL_POWERGLOVE,
  RED_POWERRANGERS,
  TEAL_FURBY,
  PURPLE_BOP_IT,
  GRAY_GAMEBOY,
  PURPLE_POLLYPOCKET,
  PURPLE_TROLLS,
} from '@lib/colors'

export const defaultTheme = createTheme()

export const MOBILE_APPBAR_HEIGHT = defaultTheme.spacing(10)
export const FOOTER_HEIGHT = '45px'
export const DESKTOP_DRAWER_WIDTH = defaultTheme.spacing(28)
export const DESKTOP_DRAWER_WIDTH_COLLAPSED = defaultTheme.spacing(9)

export const MOBILE_MIN = 'xs'
export const MOBILE_MAX = 'sm'
export const DESKTOP_MIN = 'md'
export const DESKTOP_MAX = 'xl'
export const MOBILE_BREAKPOINT = DESKTOP_MIN

const BOOTSTRAP_BREAKPOINT = 768

const headingFontBase = {
  color: SECONDARY_TEXT_COLOR,
  fontWeight: 400,
}

export const DESKTOP_CENTER_SPACE = 75
export const MOBILE_CENTER_SPACE = 20

export const FONTS = {
  LATO: '"Lato", sans-serif',
  MERRI_WEATHER: '"Merriweather", sans-serif',
  POPPINS: '"Poppins", sans-serif',
}

export const makeAlternativeTwoSidesLayout = (theme) => ({
  title: {
    fontFamily: FONTS.POPPINS,
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 1.8,
    lineHeight: '2rem',
    margin: '0 0 50px 0',
    marginBottom: 50,
    textAlign: 'center',
    [theme.breakpoints.up(BOOTSTRAP_BREAKPOINT)]: {
      textAlign: 'start',
    },
  },
  container: {
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.breakpoints.up(BOOTSTRAP_BREAKPOINT)]: {
      flexDirection: 'row',
    },
  },
  leftPanel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 0',
    background: PRIMARY_BLACK_BACKGROUND,
    [theme.breakpoints.up(BOOTSTRAP_BREAKPOINT)]: {
      flex: 1,
      padding: 0,
    },
  },
  logo: {
    height: 'auto',
    maxWidth: '35%',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up(BOOTSTRAP_BREAKPOINT)]: {
      maxWidth: '70%',
    },
  },
  rightPanel: {
    flex: 2,
    backgroundColor: '#fff',
    borderRadius: '50px 0 0 0',
    fontFamily: FONTS.POPPINS,
    padding: '40px 30px 20px 30px',
    position: 'relative',
    [theme.breakpoints.up(BOOTSTRAP_BREAKPOINT)]: {
      borderRadius: 0,
      padding: '128px 80px',
    },
  },
})

export const makeEllipsisText = (elemWidth = '100%') => ({
  maxWidth: elemWidth,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const makeGreyDottedBox = (withHover = true) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: THIRD_TEXT_COLOR,
  backgroundColor: FOURTH_GREY_BACKGROUND,
  backgroundImage:
    // https://kovart.github.io/dashed-border-generator/
    // eslint-disable-next-line max-len
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23e0e0e0' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
  ...(withHover && {
    '&:hover': {
      backgroundImage:
        // eslint-disable-next-line max-len
        "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DD2476' stroke-width='3' stroke-dasharray='5%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
    },
  }),
})

const popDesingBackground = {
  background: PURPLE_BOP_IT,
  color: COMMON_WHITE,
  '&[disabled]': {
    opacity: 0.6,
    background: PURPLE_POLLYPOCKET,
    color: COMMON_WHITE,
  },
  '&:hover': {
    background: PURPLE_TROLLS,
    color: COMMON_WHITE,
  },
}

export default createTheme({
  typography: {
    fontFamily: FONTS.LATO,
    h1: {
      ...headingFontBase,
      fontSize: '1.5rem',
      margin: '1.25rem 0',
    },
    h2: {
      ...headingFontBase,
      fontSize: '1.5rem',
      margin: '1rem 0',
      fontWeight: 700,
    },
    h3: {
      ...headingFontBase,
      fontSize: '1.125rem',
      margin: '1rem 0',
      fontWeight: 600,
    },
    button: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      margin: '0.675rem 0',
      color: THIRD_TEXT_COLOR,
    },
  },
  palette: {
    background: {
      default: COMMON_WHITE,
    },
    pop: {
      ...popDesingBackground,
    },
    grayscale: {
      primary: PRIMARY_GREY,
      secondary: SECONDARY_GREY,
      third: THIRD_GREY,
      fourth: FOURTH_GREY,
    },
    primary: {
      main: '#110f24',
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    tertiary: {
      light: TERTIARY_COLOR_LIGHT,
      main: TERTIARY_COLOR_MAIN,
      dark: TERTIARY_COLOR_DARK,
    },
    text: {
      primary: PRIMARY_TEXT_COLOR,
      secondary: SECONDARY_TEXT_COLOR,
      third: THIRD_TEXT_COLOR,
    },
    error: {
      main: '#ef3e3e',
    },
  },
  overrides: {
    MuiPickersYear: {
      root: {
        color: TEAL_DOLLHOUSE,
      },
    },
    MuiPickersMonth: {
      root: {
        color: TEAL_DOLLHOUSE,
      },
      monthSelected: {
        color: TEAL_DOLLHOUSE,
      },
    },
    MuiPickersDay: {
      day: {
        color: TEAL_DOLLHOUSE,
        '&:hover': {
          backgroundColor: TEAL_ROBOTS,
          color: COMMON_WHITE,
        },
      },
      daySelected: {
        backgroundColor: GRAY_WII,
        border: `1px solid ${TEAL_POWERGLOVE}`,
        color: TEAL_DOLLHOUSE,
        '&:hover': {
          backgroundColor: TEAL_ROBOTS,
          color: COMMON_WHITE,
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: TEAL_FURBY,
        fontWeight: 600,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: TEAL_ROBOTS,
      },
    },
    MuiCssBaseline: {
      '@global': {
        a: {
          color: '#2D9CDB',
          '&:visited, &:hover': {
            color: '#2D9CDB',
          },
        },
      },
    },
  },
  mainMargin: 26,
  projectDetails: {
    tabEmptyState: {
      fontStyle: 'italic',
      fontSize: '0.9rem',
      fontWeight: 300,
    },
  },
  formInputStyles: {
    root: {
      transition: 'all .2s ease-out',
      border: `1px solid ${GRAY_NES}`,
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '& .Mui-disabled': {
        background: COMMON_WHITE,
      },
      '& input::placeholder': {
        color: GRAY_GAMEBOY,
        opacity: 1,
        fontStyle: 'italic',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
      },
      '&:hover': {
        borderColor: TEAL_ROBOTS,
      },
      '&.Mui-focused, &:active, &:focus-within': {
        borderColor: TEAL_POWERGLOVE,
        boxShadow: '0px 0px 8px rgba(149, 145, 142, 0.3)',
      },
      '&.Mui-error': {
        borderColor: RED_STRAWBERRY_SHORT_CAKE,
        color: RED_POWERRANGERS,
        background: RED_SURPRISE,
      },
    },
    label: {
      fontSize: 16,
      lineHeight: '20px',
      marginBottom: '.5rem',
      fontWeight: 700,
      color: TEAL_ROBOTS,
      '&.Mui-focused': {
        color: TEAL_ROBOTS,
      },
      '&.Mui-error': {
        color: RED_STRAWBERRY_SHORT_CAKE,
      },
      '& > span': {
        marginLeft: defaultTheme.spacing(0.5),
        color: RED_STRAWBERRY_SHORT_CAKE,
      },
    },
    helperText: {
      margin: '0 0 10px 0',
      padding: 5,
      background: RED_SURPRISE,
      color: `${RED_POWERRANGERS} !important`,
      boxShadow: '0 0 8px rgba(149, 145, 142, 0.3)',
      borderRadius: 3,
      fontSize: 12,
    },
    optionalLabel: {
      fontSize: 10,
      textTransform: 'uppercase',
      color: TEAL_ROBOTS,
      marginLeft: defaultTheme.spacing(1),
    },
    iconWrapper: {
      width: 45,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button': {
        borderRadius: 0,
      },
      '& div': {
        width: 20,
        height: 20,
      },
    },
    endIconWrapper: {
      background: 'rgba(222, 219, 217, 0.5)',
      marginRight: -18,
      height: 'calc(100% + 26px)',
    },
    input: {
      overflow: 'hidden',
      background: GRAY_WII,
      borderRadius: 10,
      color: TEAL_DOLLHOUSE,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 18,
      fontWeight: 500,
      height: 52,
      margin: '10px 0 !important',
      width: '100%',
      padding: '12px 18px',
      '&.Mui-disabled': {
        background: COMMON_WHITE,
      },
    },
    multilineInput: {
      height: 'auto',
      minHeight: 40,
      overflow: 'hidden',
      background: GRAY_WII,
      borderRadius: 10,
      color: TEAL_DOLLHOUSE,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 18,
      fontWeight: 500,
      margin: '10px 0 !important',
      width: '100%',
      padding: '12px 18px',
    },
    select: {
      '&:focus': {
        background: 'transparent',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${TEAL_ROBOTS}`,
      },
      '&:not(.Mui-disabled):hover': {
        borderColor: TEAL_ROBOTS,
      },
    },
    autocompleteDisabled: {
      background: COMMON_WHITE,
    },
    autocomplete: {
      borderRadius: 10,
      color: TEAL_DOLLHOUSE,
      fontSize: 18,
      fontWeight: 500,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      height: '100%',
      border: 'none !important',
      '& .MuiAutocomplete-input': {
        padding: '0 !important',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: TEAL_ROBOTS,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: TEAL_ROBOTS,
      },
    },
  },
  appBarIcon: {
    background: SECONDARY_GREY_BACKGROUND,
    color: PRIMARY_ICON_COLOR,
    borderRadius: 0,
    padding: 8,
  },
  makeMobileAddBtn: {
    minWidth: 'auto',
    padding: 6,
  },
  formGeneralStyles: {
    fieldsRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& > div.MuiFormControl-root': {
        width: '100%',
        [defaultTheme.breakpoints.up(MOBILE_BREAKPOINT)]: {
          width: '48.5%',
        },
      },
    },
    formSection: {
      marginBottom: 40,
      '& > p': {
        marginTop: 0,
        fontSize: 24,
        fontWeight: 600,
        color: TEAL_FURBY,
      },
    },
    formSlice: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '& > .MuiFormControl-root': {
        marginBottom: 30,
        '&:last-child': {
          marginBottom: 0,
        },
      },
      [defaultTheme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        flex: 1,
        '&:first-child': {
          marginRight: 40,
        },
      },
    },
    actions: {
      zIndex: 3,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      boxShadow: '0 -2px 6px 2px rgba(98, 94, 91, 0.2)',
      background: COMMON_WHITE,
      display: 'flex',
      justifyContent: 'space-between',
      '& button': {
        maxWidth: 250,
        margin: 0,
      },
      [defaultTheme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        padding: '20px 75px',
      },
    },
  },
  projectDetailsBox: {
    [defaultTheme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      margin: 0,
      marginTop: defaultTheme.spacing(2),
      border: `1px solid ${BOX_BORDER_COLOR}`,
      borderRadius: 4,
      padding: 0,
      paddingTop: 0,
    },
  },
  popAvatar: {
    ...popDesingBackground,
    width: defaultTheme.spacing(4),
    height: defaultTheme.spacing(4),
    fontSize: '0.80rem',
    textTransform: 'uppercase',
    border: `1px solid ${COMMON_WHITE}`,
  },
  npiTable: {
    hiddenNpiElement: {
      opacity: '0.3',
      position: 'relative',
    },
    actionBtn: {
      margin: 0,
      padding: '2px 5px',
    },
    documentsTable: {
      tableLayout: 'fixed',
      background: COMMON_WHITE,
      '& > thead > tr > th': {
        padding: 10,
      },
      '& > tbody > tr': {
        '& > td': {
          padding: 10,
        },
        '&:nth-child(odd)': {
          background: NPI_GREY,
        },
      },
    },
    filesTitle: {
      display: 'block',
      marginTop: 0,
      marginBottom: 20,
      fontSize: '1.2rem',
    },
  },
  topBannerStyles: {
    padding: `${defaultTheme.spacing(1.2)}px ${defaultTheme.spacing(1)}px`,
    background: COMMON_WHITE,
    border: `1px solid ${TEAL_FURBY}`,
    borderWidth: '0 0 1px 0',
    fontSize: '1rem',
    marginBottom: defaultTheme.spacing(3),
    borderRadius: 3,
    boxShadow: '3px 4px 4px rgba(0, 0, 0, 0.1)',
    [defaultTheme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      borderWidth: '1px 1px 1px 5px',
    },
    '& strong': {
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
  },
})

// New design

export const lightButton = {
  background: GRAY_WII,
  borderRadius: '10px',
  textTransform: 'lowercase',
  color: PURPLE_MEGATRON,
  fontSize: 15,
  lineHeight: '38px',
  fontWeight: 500,
  padding: '0 15px',
  '&:hover': {
    background: GRAY_NES,
  },
}
