import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'

import { NativeSelect } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ArrowIcon from '@public/svg/icons/arrow-down.svg'

import PopButton from '@forms/PopButton'

import { internalUsersFilterByLocation, internalUsersFilterByRole } from '@lib/apollo/apolloCache'
import { TEAL_DOLLHOUSE } from '@lib/colors'
import { COUNTRIES } from '@lib/constants'
import { serializeData } from '@lib/tracking'
import { USER_ROLES, USER_ROLES_LABELS } from '@lib/userAuth'

import { GET_ACCOUNT_USERS_COUNTRIES } from '@graphql/users/queries'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  dropIcon: {
    top: 'calc(50% - 7px)',
    width: 11,
  },
  select: {
    color: TEAL_DOLLHOUSE,
    width: 'auto',
    fontSize: 14,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

const CompaniesFilters = () => {
  const classes = useStyles()
  const roleFilter = useReactiveVar(internalUsersFilterByRole)
  const locationFilter = useReactiveVar(internalUsersFilterByLocation)

  const { data } = useQuery(GET_ACCOUNT_USERS_COUNTRIES)
  const countrieCodes = data?.accountUserCountries

  return (
    <div className={classes.root}>
      <NativeSelect
        classes={{ select: classes.select, icon: classes.dropIcon }}
        defaultValue=""
        value={roleFilter ?? ''}
        inputProps={{
          name: 'role',
        }}
        onChange={(e) => {
          internalUsersFilterByRole(e.target.value)
        }}
        IconComponent={(props) => <SvgLoader {...ArrowIcon} {...props} />}
        disableUnderline
      >
        <option value="" disabled>
          Role
        </option>
        <option value={USER_ROLES.MEMBER}>{USER_ROLES_LABELS[USER_ROLES.MEMBER]}</option>
        <option value={USER_ROLES.ADMIN}>{USER_ROLES_LABELS[USER_ROLES.ADMIN]}</option>
        <option value={USER_ROLES.OWNER}>{USER_ROLES_LABELS[USER_ROLES.SUPER_ADMIN]}</option>
      </NativeSelect>
      <NativeSelect
        classes={{ select: classes.select, icon: classes.dropIcon }}
        defaultValue=""
        value={locationFilter ?? ''}
        style={{ marginLeft: 20, maxWidth: 100 }}
        inputProps={{
          name: 'location',
        }}
        onChange={(e) => {
          internalUsersFilterByLocation(e.target.value)
        }}
        IconComponent={(props) => <SvgLoader {...ArrowIcon} {...props} />}
        disableUnderline
      >
        <option value="" disabled>
          Location
        </option>
        {countrieCodes?.map((countryCode) => (
          <option value={countryCode} key={countryCode}>
            {COUNTRIES[countryCode]}
          </option>
        ))}
      </NativeSelect>
      <PopButton
        onClick={() => {
          internalUsersFilterByLocation(null)
          internalUsersFilterByRole(null)
        }}
        style={{ marginLeft: 20 }}
        size="small"
        btnStyle="simple"
        disabled={!roleFilter && !locationFilter}
        data-tracking-info={serializeData({
          id: 'admin-console_companies-clear-filters-button_click',
        })}
      >
        clear filters
      </PopButton>
    </div>
  )
}

export default CompaniesFilters
