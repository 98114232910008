import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import OfficeIcon from '@public/svg/icons/company.svg'

import PopComboField from '@forms/PopComboField'

import { externalUsersFilter } from '@lib/apollo/apolloCache'

import { GET_ACCOUNTS_BY_TYPE } from '@graphql/users/queries'

import { getUsersByRole } from '@components_pop/AdminConsole/utils'

const DEFAULT_MIN_SEARCH_VALUE_LENGTH = 2
const DEFAULT_DEBOUNCE_TIME = 500

const ExternalUsersfilters = ({ userType }) => {
  const [companies, setCompanies] = useState([])
  const [currentCompany, setCurrentCompany] = useState('')

  const [callGetAccounts, { data }] = useLazyQuery(GET_ACCOUNTS_BY_TYPE)

  const handleSearch = (newValue) => {
    callGetAccounts({
      variables: {
        accountTypes: [userType],
        search: newValue?.trim(),
        limit: 15,
      },
    })
  }

  const debounceSearch = useRef(debounce(handleSearch, DEFAULT_DEBOUNCE_TIME))

  useEffect(() => {
    if (data) {
      setCompanies(
        data.accounts?.accounts?.reduce((currentCompanies, currentAccount) => {
          if (currentCompanies.find((company) => company.id === currentAccount.id))
            return currentCompanies

          return [
            ...currentCompanies,
            {
              id: currentAccount?.id,
              name: currentAccount?.name,
              hasOwner: !!getUsersByRole.OWNER(currentAccount).length,
            },
          ]
        }, [])
      )
    }
  }, [setCompanies, data])

  return (
    <PopComboField
      inputValue={currentCompany}
      name="externalUsersFilter"
      placeholder="filter by company"
      noOptionsText="No companies found."
      options={companies}
      inputIcon={OfficeIcon}
      getOptionLabel={(option) => option.searchLabel ?? option.name ?? ''}
      getOptionSelected={(opt, selected) => opt.id === selected.id}
      getOptionDisabled={(opt) => opt.id === 'no_results'}
      onInputChange={(_e, newValue, reason) => {
        if (reason === 'reset') return

        setCompanies([])

        if (newValue.length >= DEFAULT_MIN_SEARCH_VALUE_LENGTH) {
          debounceSearch.current(newValue)
        }

        setCurrentCompany(newValue)
      }}
      onChange={(_e, company, reason) => {
        if (!['select-option', 'clear'].includes(reason)) {
          return
        }

        if (reason === 'clear') {
          externalUsersFilter(null)
          setCurrentCompany('')
          return
        }

        externalUsersFilter(company.id)
        setCurrentCompany(company.name)
        setCompanies([])
      }}
      clearOnEscape={false}
      clearOnBlur={false}
      fullWidth
      freeSolo
    />
  )
}

ExternalUsersfilters.propTypes = {
  userType: PropTypes.string,
}

export default ExternalUsersfilters
