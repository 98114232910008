import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG } from 'react-svg'

import { makeStyles } from '@material-ui/core/styles'

import HourGlassIcon from '@public/svg/icons/hour-glass.svg'

const useStyles = makeStyles(() => ({
  spinIcon: {
    animation: '$spin 1000ms infinite ease',
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

const SvgLoader = ({ src, className, wrapper = 'div', width, height, title, isLoading, style }) => {
  const classes = useStyles()

  const iconClasses = clsx([className, { [classes.spinIcon]: isLoading }])

  return (
    <ReactSVG
      className={iconClasses}
      src={isLoading ? HourGlassIcon.src : src ?? ''}
      wrapper={wrapper}
      width={width}
      height={height}
      title={title}
      alt={title}
      style={style}
    />
  )
}

SvgLoader.propTypes = {
  isLoading: PropTypes.bool,
  style: PropTypes.any,
  src: PropTypes.string,
  className: PropTypes.string,
  wrapper: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
}

export default SvgLoader
