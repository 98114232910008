import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { Collapse } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import ArrowDownIcon from '@public/svg/icons/arrow-down.svg'
import ArrowUpIcon from '@public/svg/icons/arrow-up.svg'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import { TEAL_GLOVE, GRAY_WII, GRAY_NES, TEAL_FURBY } from '@lib/colors'
import { serializeData } from '@lib/tracking'

import Pagination from '@components_pop/Pagination'
import SvgLoader from '@components_pop/SvgLoader'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles(() => ({
  tableHeaderRoot: {
    '& th': {
      whiteSpace: 'nowrap',
      borderBottom: `1px solid ${GRAY_NES}`,
      textTransform: 'uppercase',
      padding: 10,
    },
  },
  clickableHeader: {
    cursor: 'pointer',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  headerText: {
    display: 'flex',
    fontSize: 10,
    color: TEAL_GLOVE,
    fontWeight: '400',
    '& > div': {
      marginLeft: 6,
      marginTop: 3,
      width: 15,
      height: 15,
    },
  },
  icon: {
    width: 20,
    height: 20,
  },
  tableRow: {
    '&:last-child > td': {
      border: 'none',
    },
  },
  draggableRow: {
    display: 'table',
  },
  tableCell: {
    fontSize: 14,
    color: TEAL_FURBY,
    borderColor: GRAY_WII,
  },
  hiddenHeaders: {
    '& th': {
      padding: 0,
    },
  },
}))

const EXPANSION_TRIGGER_BLACKLIST_ELEMENTS = ['svg', 'button', 'path', 'span']

const RenderMappedTabledView = ({
  item,
  i,
  cellClassNames = {},
  WithExpansionContent,
  customExpansionTrigger,
  listItemsMap,
  tableName,
  headers,
  hasTablePermission,
  expansionArrowClass,
  noScrollOnExtensionOpen,
  isExpansionTriggerAtStart,
  hasAllRowExpansionTrigger,
  dragProvided,
  dragSnapshot,
}) => {
  const {
    row = {},
    cells,
    isExpansionContentOpened,
  } = listItemsMap(item, i, { dragProvided, dragSnapshot })

  const [open, setOpen] = useState(isExpansionContentOpened)
  const isUserPermittedRow = row?.roles ? hasTablePermission(row.roles) : true
  const classes = useStyles()
  const expansionTableRowRef = useRef()

  const debouncedScrollIntoView = _.debounce(() => {
    if (open && expansionTableRowRef && expansionTableRowRef.current) {
      expansionTableRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, 200)

  useEffect(() => {
    if (dragSnapshot.isDragging && open) setOpen(false)
  }, [setOpen, open, dragSnapshot.isDragging])

  useEffect(() => {
    if (isExpansionContentOpened) debouncedScrollIntoView()
  }, [isExpansionContentOpened])

  const expand = (e, forceClick) => {
    e.stopPropagation()
    if (
      !forceClick &&
      hasAllRowExpansionTrigger &&
      EXPANSION_TRIGGER_BLACKLIST_ELEMENTS.includes(e.target.tagName?.toLowerCase())
    )
      return

    setOpen(!open)

    if (noScrollOnExtensionOpen) return
    debouncedScrollIntoView()
  }

  const renderExpansionTriggerCell = () =>
    WithExpansionContent ? (
      <TableCell
        className={clsx({
          ...cellClassNames,
          ...(expansionArrowClass || {}),
          [classes.tableCell]: true,
        })}
      >
        {customExpansionTrigger ? (
          customExpansionTrigger({ expand, item })
        ) : (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => expand(e, true)}
            data-test-id={`${tableName}-list-table-row-expand--button-${item.id}`}
            data-tracking-info={serializeData({
              id: `${tableName}-list-table_row-expand_click`,
              rowId: item.id,
              isOpen: !open,
            })}
          >
            <SvgLoader className={classes.icon} {...(open ? ArrowUpIcon : ArrowDownIcon)} />
          </IconButton>
        )}
      </TableCell>
    ) : null

  return (
    <>
      <TableRow
        hover={row.hover ?? true}
        ref={dragProvided.innerRef}
        className={clsx({
          ...(row.classNames || {}),
          [classes.tableRow]: true,
          [classes.draggableRow]: dragSnapshot.isDragging,
          [classes.clickableRow]: hasAllRowExpansionTrigger || isUserPermittedRow,
        })}
        data-tracking-info={serializeData({
          id: `${tableName}-list-table_row_click`,
          rowId: item.id,
        })}
        data-test-id={`${tableName}-list--row-${item[row.key || 'id']}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isUserPermittedRow && { onClick: hasAllRowExpansionTrigger ? expand : row.action })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dragProvided.draggableProps}
      >
        {isExpansionTriggerAtStart && renderExpansionTriggerCell()}

        {cells.map(
          (cell, j) =>
            hasTablePermission(cell.allowedRoles) && (
              <TableCell
                // eslint-disable-next-line react/no-array-index-key
                key={`${tableName}-row-${i}-cell-${j}`}
                colSpan={cell.colSpan}
                className={clsx({
                  ...cellClassNames,
                  ...(cell.classNames || {}),
                  [classes.tableCell]: true,
                })}
                {...cell.actions}
              >
                {cell.content}
              </TableCell>
            )
        )}

        {!isExpansionTriggerAtStart && renderExpansionTriggerCell()}
      </TableRow>

      {WithExpansionContent && open && (
        <TableRow ref={expansionTableRowRef}>
          <TableCell style={{ padding: 0 }} colSpan={headers.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <WithExpansionContent rowItem={item} index={i} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

RenderMappedTabledView.propTypes = {
  item: PropTypes.any.isRequired,
  i: PropTypes.any.isRequired,
  cellClassNames: PropTypes.object,
  WithExpansionContent: PropTypes.any,
  listItemsMap: PropTypes.any,
  customExpansionTrigger: PropTypes.func,
  tableName: PropTypes.any,
  headers: PropTypes.any,
  hasTablePermission: PropTypes.any,
  expansionArrowClass: PropTypes.any,
  noScrollOnExtensionOpen: PropTypes.bool,
  isExpansionTriggerAtStart: PropTypes.bool,
  hasAllRowExpansionTrigger: PropTypes.bool,
  dragProvided: PropTypes.shape({
    innerRef: PropTypes.func,
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
  }),
  dragSnapshot: PropTypes.object,
}

const GembahTableView = ({
  tableClassNames,
  cellClassNames,
  listItemsMap,
  tableName,
  headers,
  listItems,
  emptyState,
  onSortClick,
  children,
  paginationProps,
  WithExpansionContent,
  expansionArrowClass,
  keyProp,
  noScrollOnExtensionOpen,
  hasVisibleHeadersOnEmpty,
  customExpansionTrigger,
  isExpansionTriggerAtStart,
  hasHiddenTableHeaders,
  hasAllRowExpansionTrigger,
  dndProps,
  isLoadingSort = false,
  columnSorting,
}) => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoint()
  const accountUser = useReactiveVar(currentAccountUser)

  const hasTablePermission = (hasPermission) => (hasPermission ? hasPermission(accountUser) : true)

  const tableHeaderClasses = clsx([
    classes.tableHeaderRoot,
    {
      [classes.hiddenHeaders]: hasHiddenTableHeaders,
    },
  ])

  return (
    <DragDropContext onDragEnd={dndProps?.onDragAndDrop}>
      <Droppable droppableId={dndProps?.id ?? tableName} isDragDisabled={!dndProps}>
        {(dropProvided) => (
          <>
            <TableContainer>
              <Table
                stickyHeader
                className={clsx({ ...tableClassNames })}
                data-test-id={`${tableName}-list--table`}
              >
                {isDesktop && (hasVisibleHeadersOnEmpty || listItems.length > 0) && (
                  <TableHead
                    classes={{ root: tableHeaderClasses }}
                    data-test-id={`${tableName}-list--table-head`}
                  >
                    <TableRow>
                      {headers.map(
                        (h) =>
                          hasTablePermission(h.allowedRoles) && (
                            <TableCell
                              key={h.name}
                              colSpan={h.colSpan}
                              width={h.width}
                              className={clsx({
                                ...(h.classNames || {}),
                                [classes.clickableHeader]: h.sortable && !isLoadingSort,
                              })}
                              {...(h.sortable &&
                                !isLoadingSort && {
                                  onClick: () => onSortClick(h.name),
                                  'data-tracking-info': serializeData({
                                    id: `${tableName}-list-table_row-sort_click`,
                                    sortBy: h.name,
                                  }),
                                })}
                            >
                              <div className={classes.headerText}>
                                {h.text}{' '}
                                {h.sortable && columnSorting?.replace('-', '') === h.name && (
                                  <SvgLoader
                                    {...(columnSorting.startsWith('-')
                                      ? ArrowDownIcon
                                      : ArrowUpIcon)}
                                    isLoading={isLoadingSort}
                                  />
                                )}
                              </div>
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody
                  data-test-id={`${tableName}-list--table-body`}
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  {listItems.length === 0
                    ? emptyState
                    : listItems.map((item, i) =>
                        children ? (
                          children({ item, classes, hasPermission: hasTablePermission })
                        ) : (
                          <Draggable
                            key={item[keyProp || 'id']}
                            draggableId={item[keyProp || 'id']}
                            index={i + 1}
                            shouldRespectForceTouch={false}
                          >
                            {(dragProvided, dragSnapshot) => (
                              <RenderMappedTabledView
                                key={item[keyProp || 'id']}
                                item={item}
                                i={i}
                                cellClassNames={cellClassNames}
                                customExpansionTrigger={customExpansionTrigger}
                                WithExpansionContent={WithExpansionContent}
                                listItemsMap={listItemsMap}
                                tableName={tableName}
                                headers={headers}
                                hasTablePermission={hasTablePermission}
                                expansionArrowClass={expansionArrowClass}
                                noScrollOnExtensionOpen={noScrollOnExtensionOpen}
                                isExpansionTriggerAtStart={isExpansionTriggerAtStart}
                                hasAllRowExpansionTrigger={hasAllRowExpansionTrigger}
                                dragProvided={dragProvided}
                                dragSnapshot={dragSnapshot}
                              />
                            )}
                          </Draggable>
                        )
                      )}
                  {dropProvided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
            {paginationProps && (
              <Pagination
                page={paginationProps.page}
                itemsCount={paginationProps.totalCount}
                onChange={paginationProps.onChange}
              />
            )}
          </>
        )}
      </Droppable>
    </DragDropContext>
  )
}

GembahTableView.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableClassNames: PropTypes.object,
  cellClassNames: PropTypes.object,
  paginationProps: PropTypes.object,
  onSortClick: PropTypes.func,
  columnSorting: PropTypes.string,
  isLoadingSort: PropTypes.bool,
  customExpansionTrigger: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyState: PropTypes.element.isRequired,
  listItemsMap: PropTypes.func,
  dndProps: PropTypes.shape({
    onDragAndDrop: PropTypes.func,
    id: PropTypes.string,
  }),
  children: PropTypes.node,
  WithExpansionContent: PropTypes.any,
  expansionArrowClass: PropTypes.any,
  keyProp: PropTypes.string,
  noScrollOnExtensionOpen: PropTypes.bool,
  hasVisibleHeadersOnEmpty: PropTypes.bool,
  isExpansionTriggerAtStart: PropTypes.bool,
  hasHiddenTableHeaders: PropTypes.bool,
  hasAllRowExpansionTrigger: PropTypes.bool,
}

GembahTableView.defaultProps = {
  onSortClick: () => {},
  children: null,
  listItemsMap: () => {},
  tableClassNames: {},
  paginationProps: null,
}

export default GembahTableView
