import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core'

import PopButton from '@forms/PopButton'

const useStyles = makeStyles(() => ({
  cancelButton: {
    marginLeft: (props) => (props.fullWidth ? 0 : '1rem'),
  },
}))

export default function CancelButton({ onClick, children, fullWidth, className, ...btnProps }) {
  const classes = useStyles({ fullWidth })
  const btnClasses = clsx(className, classes.cancelButton)

  return (
    <PopButton btnStyle="simple" className={btnClasses} onClick={onClick} {...btnProps}>
      {children}
    </PopButton>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}
