import { gql } from '@apollo/client'

import {
  CONTRACTOR_TERMS_VERSION_GQL_FIELD,
  CUSTOMER_TERMS_VERSION_GQL_FIELD,
} from '@graphql/constants'

export const ACCOUNT_USER_TYPE_FRAGMENT = gql`
  fragment AccountUserTypeFragment on AccountUserType {
    id
    email
    firstName
    lastName
    fullName
    status
    userId
    invitedBy {
      id
      fullName
    }
    invitedAt
    roles {
      id
      role
    }
    account {
      id
      type
      name
      accountUsers {
        roles {
          id
          role
        }
      }
      accountProfile {
        id
        name
        primaryLocation {
          name
          longitude
          latitude
          country
          locality
          region
        }
      }
    }
    user {
      id
      lastLogin
      isSuperuser
      fullName
      firstName
      lastName
      profile {
        id
        lastName
        firstName
        email
        name
        avatar {
          filename
          url
        }
        location {
          name
          longitude
          latitude
          country
          locality
          region
        }
      }
    }
  }
`

export const GET_DEPLOYMENT_VERSION = gql`
  query GetDeploymentVersion {
    deploymentVersion {
      currentVersion
    }
  }
`

export const GET_DEPLOYMENT_VERSION_MISMATCH = gql`
  query GetMismatchedDeploymentVersion($clientVersion: String) {
    mismatchedDeploymentVersion(clientVersion: $clientVersion) {
      currentVersion
      clientVersion
    }
  }
`

export const GET_AUTH_USER = gql`
  query GetMe {
    me {
      userAccounts {
        id
        status
        title
        fullName
        roles {
          id
          role
        }
        account {
          id
          type
          accountProfile {
            name
          }
        }
      }
      profile {
        id
        title
        lastName
        firstName
        name
        email
        avatar {
          filename
          url
        }
        location {
          name
          longitude
          latitude
          country
          locality
          region
        }
      }
      id
      notificationUuid
      email
      firstName
      availability
      coveredByEmail
      coveredByUser {
        id
        firstName
        lastName
      }
      timeZone
      lastName
      groups
      isSwitched
      flags {
        name
        active
      }
      profilePicture {
        url
        filename
      }
      staffUser {
        id
        flags {
          accountManager
        }
        gembahUserTier
      }
      customer {
        id
        roles {
          id
          role
        }
        company {
          id
          name
          website
          location
          phone
        }
        flags {
          ${CUSTOMER_TERMS_VERSION_GQL_FIELD}
        }
      }
      stripeInvoices {
        id
        amount
        invoiceNumber
        invoiceDate
        invoicePaidDate
        dueDate
        hostedInvoiceUrl
        invoicePdf
        paid
        status
      }
      contractor {
        id
        title
        phone
        website
        weeklyAvailability
        profileStatus
        primaryAddress {
          locality
          country
        }
        portfolioItems {
          id
          link
          label
        }
        flags {
          ${CONTRACTOR_TERMS_VERSION_GQL_FIELD}
        }
      }
      accounts {
        id
        type
        accountUsers {
          ...AccountUserTypeFragment
        }
        accountProfile {
          id
          name
          website
          phone
          phoneType
          primaryLocation {
            name
            longitude
            latitude
            country
            locality
            region
          }
        }
      }
    }
  }
  ${ACCOUNT_USER_TYPE_FRAGMENT}
`
