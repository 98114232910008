import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { paginationLimit, paginationOffset, paginationPage } from '@lib/apollo/apolloCache'

import { DEFAULT_PAGINATION_LIMIT } from '@components_pop/Pagination/constants'

const usePagination = ({ limit = DEFAULT_PAGINATION_LIMIT, defaultPage = 1 } = {}) => {
  const cachedPage = useReactiveVar(paginationPage)
  const [page, setPage] = useState(cachedPage)

  useEffect(() => {
    paginationLimit(limit)
    paginationPage(defaultPage)
    paginationOffset(0)
  }, [])

  const handlePageChange = (newPage = cachedPage) => {
    setPage(newPage)
    paginationPage(newPage)
    paginationOffset((newPage - 1) * paginationLimit())
  }

  return [page, handlePageChange]
}

usePagination.propTypes = {
  limit: PropTypes.number,
}

export default usePagination
