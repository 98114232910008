import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'

import RadioDisabledIcon from '@public/svg/icons/radio-disabled-icon.svg'
import RadioDisabledCheckedIcon from '@public/svg/icons/radio-disabled-selected-icon.svg'
import RadioIcon from '@public/svg/icons/radio-icon.svg'
import RadioCheckedIcon from '@public/svg/icons/radio-selected-icon.svg'

import { GRAY_NICKELARCADE, TEAL_FURBY } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  ...theme.formInputStyles,
  rowRadios: {
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginRight: 60,
    },
  },
  controlRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 10,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginBottom: 35,
    },
  },
  radioGroupLabel: {
    ...theme.formInputStyles.label,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioLabel: {
    fontSize: 18,
    color: TEAL_FURBY,
  },
  radioHelperLabel: {
    fontSize: 10,
    color: GRAY_NICKELARCADE,
  },
  radioRoot: {
    paddingTop: 4,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  radioIcon: {
    marginTop: -7,
    width: 18,
  },
  disabled: {
    '& > *': {
      color: GRAY_NICKELARCADE,
    },
  },
  radioAdditionalContent: {
    margin: '-25px 0 35px',
  },
}))

const PopRadioGroup = ({
  name,
  control,
  radioOptions,
  error,
  label,
  id,
  helperText,
  FormLabelProps,
  fullWidth,
  className,
  disableHelperText,
  'data-test-id': dataTestId,
  required,
  row,
  ...otherRadioProps
}) => {
  const classes = useStyles()
  const currentValue = control.watchInternal(name)
  const { isDesktop } = useBreakpoint()

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      {label && (
        <FormLabel
          className={classes.radioGroupLabel}
          htmlFor={id}
          error={error}
          {...FormLabelProps}
        >
          {label}
          {!!required && <span>*</span>}
        </FormLabel>
      )}
      <Controller
        name={name}
        defaultValue={null}
        control={control}
        render={({ value, onChange }) => (
          <RadioGroup
            data-testid={dataTestId}
            name={name}
            value={value}
            onChange={onChange}
            classes={{ row: classes.rowRadios }}
            row={isDesktop ? row : false}
            {...otherRadioProps}
          >
            {radioOptions.map((radioOption) => (
              <Fragment key={radioOption.value}>
                <FormControlLabel
                  classes={{
                    root: classes.controlRoot,
                    label: classes.label,
                    disabled: classes.disabled,
                  }}
                  value={radioOption.value}
                  disabled={radioOption.disabled}
                  control={
                    <Radio
                      disabled={radioOption.disabled}
                      icon={
                        <SvgLoader
                          className={classes.radioIcon}
                          {...(radioOption.disabled ? RadioDisabledIcon : RadioIcon)}
                        />
                      }
                      checkedIcon={
                        <SvgLoader
                          className={classes.radioIcon}
                          {...(radioOption.disabled ? RadioDisabledCheckedIcon : RadioCheckedIcon)}
                        />
                      }
                      classes={{ root: classes.radioRoot, checked: classes.radioRoot }}
                      size="small"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    />
                  }
                  label={
                    <>
                      <span className={classes.radioLabel}>{radioOption.label}</span>
                      {radioOption.helperLabel && (
                        <span className={classes.radioHelperLabel}>{radioOption.helperLabel}</span>
                      )}
                    </>
                  }
                />
                {currentValue === radioOption.value &&
                  !radioOption.disabled &&
                  radioOption.additionalContent && (
                    <div className={classes.radioAdditionalContent}>
                      {radioOption.additionalContent}
                    </div>
                  )}
              </Fragment>
            ))}
          </RadioGroup>
        )}
      />
      {!disableHelperText && error && (
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

PopRadioGroup.propTypes = {
  radioOptions: PropTypes.array,
  control: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
  row: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  disableHelperText: PropTypes.bool,
  FormLabelProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  'data-test-id': PropTypes.string,
  required: PropTypes.bool,
}

export default PopRadioGroup
