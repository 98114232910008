import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'

import {
  currentAccountOwner,
  currentAccountUsers,
  paginationLimit,
  paginationOffset,
} from '@lib/apollo/apolloCache'

import UserDrawerContent from '@components_pop/AdminConsole/Shared/UserDrawerContent'
import UserRow from '@components_pop/AdminConsole/Shared/UserRow'
import { USER_PAGINATION_LIMIT } from '@components_pop/AdminConsole/constants'
import Pagination from '@components_pop/Pagination'
import usePagination from '@components_pop/Pagination/hooks'
import PopDrawer from '@components_pop/PopDrawer'

const MembersView = () => {
  const [page, setPage] = usePagination({ limit: USER_PAGINATION_LIMIT })
  const offset = useReactiveVar(paginationOffset)
  const limit = useReactiveVar(paginationLimit)
  const accountOwner = useReactiveVar(currentAccountOwner)
  const accountUsers = useReactiveVar(currentAccountUsers) ?? []
  const [member, setMember] = useState()

  const handleOnClose = () => setMember(null)

  return (
    <div style={{ paddingBottom: 50 }}>
      {accountUsers.slice(offset, offset + limit).map((user) => (
        <UserRow key={user.id} user={user} onUserClick={setMember} showUserType={false} />
      ))}
      <Pagination page={page} itemsCount={accountUsers?.length ?? 0} onChange={setPage} />

      <PopDrawer open={!!member} onClose={handleOnClose} title="Edit Member">
        <UserDrawerContent
          user={member}
          onClose={handleOnClose}
          showUserType={false}
          hasOwner={!!accountOwner}
        />
      </PopDrawer>
    </div>
  )
}

export default MembersView
