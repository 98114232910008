import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { companiesOrderBy, currentAccountUser } from '@lib/apollo/apolloCache'
import { hasPermission } from '@lib/userAuth'

import { MemberRowBadge } from '@components_pop/AdminConsole/styles'
import { getUsersByRole } from '@components_pop/AdminConsole/utils'
import GembahTable from '@components_pop/Table'

import EmptyState from './empty'

const CompaniesView = ({ companies, goToEdit, page, setPage, totalCount }) => {
  const accountUser = useReactiveVar(currentAccountUser)

  const headers = [
    {
      name: 'type',
      text: 'Account Type',
      sortable: false,
    },
    {
      name: 'name',
      text: 'Company name',
      sortable: false,
    },
    {
      name: 'owner',
      text: 'Account Owner',
      sortable: false,
    },
    {
      name: 'email',
      text: 'Owner Email',
      sortable: false,
    },
  ]

  const listItemsMappingDesktop = (company) => {
    const companyOwner = getUsersByRole.OWNER(company)[0]

    return {
      row: hasPermission.ADMIN(accountUser)
        ? {
            action: () => goToEdit(company.id),
          }
        : {},
      cells: [
        {
          content: (
            <MemberRowBadge usertype={company?.type} maxWidth={70} hasRounderBorder>
              {company?.type}
            </MemberRowBadge>
          ),
        },
        {
          content: company?.accountProfile?.name ?? '-',
        },
        {
          content: companyOwner?.fullName ?? '-',
        },
        {
          content: companyOwner?.email ?? '-',
        },
      ],
    }
  }

  return (
    <GembahTable
      emptyState={<EmptyState />}
      headers={headers}
      listItems={companies}
      listItemsMap={listItemsMappingDesktop}
      paginationProps={{
        page,
        totalCount,
        onChange: setPage,
      }}
      orderByVar={companiesOrderBy}
      tableName="admin-console--company"
    />
  )
}

CompaniesView.propTypes = {
  companies: PropTypes.array,
  goToEdit: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalCount: PropTypes.number,
}

export default CompaniesView
