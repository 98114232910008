import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { PopFormCheckbox } from '@forms/PopCheckBox'
import PopRadioGroup from '@forms/PopRadioGroup'

import { GRAY_WII, RED_JEM, TEAL_FURBY } from '@lib/colors'
import { ACCOUNT_TYPES, USER_ROLES, USER_ROLES_LABELS } from '@lib/userAuth'

const useStyles = makeStyles(() => ({
  rootConfirmOwner: {
    display: 'flex',
    flexDirection: 'column',
    background: GRAY_WII,
    padding: '5px 30px',
    borderRadius: 5,
    '& > span': {
      fontSize: 14,
      fontStyle: 'italic',
      color: RED_JEM,
    },
  },
  checkBoxLabelClass: {
    fontSize: 18,
    color: TEAL_FURBY,
  },
}))

const OwnerConfirmation = ({ control }) => {
  const classes = useStyles()

  return (
    <div className={classes.rootConfirmOwner}>
      <span>
        The selected company already has an owner. By changing ownership, the permissions granted to
        the current owner will be lost and they will be granted an admin role.
      </span>
      <PopFormCheckbox
        labelClass={classes.checkBoxLabelClass}
        control={control}
        label="Confirm change of ownership"
        name="confirmOwner"
      />
    </div>
  )
}

OwnerConfirmation.propTypes = {
  control: PropTypes.object,
}

const Permissions = ({ control, userType, hasOwner, disableAll, disabledPermissions = {} }) => {
  const permissions = [
    userType === ACCOUNT_TYPES.GEMBAH
      ? {
          value: USER_ROLES.OWNER,
          label: USER_ROLES_LABELS[USER_ROLES.SUPER_ADMIN],
          helperLabel: 'A super admin has access to all settings',
          disabled: disableAll,
        }
      : {
          value: USER_ROLES.OWNER,
          label: USER_ROLES_LABELS[USER_ROLES.OWNER],
          helperLabel:
            'An owner has access to all settings, including organization-wide controls and billing.',
          additionalContent: hasOwner ? <OwnerConfirmation control={control} /> : null,
          disabled: disableAll,
        },
    {
      value: USER_ROLES.ADMIN,
      label: USER_ROLES_LABELS[USER_ROLES.ADMIN],
      helperLabel: 'An admin has access to invite and manage members.',
      disabled: disableAll,
    },
    {
      value: USER_ROLES.MEMBER,
      label: USER_ROLES_LABELS[USER_ROLES.MEMBER],
      helperLabel: 'A member has access to all product level features in the application.',
      disabled: disableAll,
    },
  ].map((permission) => {
    if (!disabledPermissions[permission.value]) return permission

    return {
      ...permission,
      disabled: disableAll || disabledPermissions[permission.value],
    }
  })

  return <PopRadioGroup control={control} name="permission" radioOptions={permissions} />
}

Permissions.propTypes = {
  control: PropTypes.object,
  userType: PropTypes.string,
  hasOwner: PropTypes.bool,
  disableAll: PropTypes.bool,
  disabledPermissions: PropTypes.object,
}

export default Permissions
