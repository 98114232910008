import { gql } from '@apollo/client'

import { ACCOUNT_USER_TYPE_FRAGMENT } from '@graphql/user/queries'

export const GET_ACCOUNT_USERS_COUNTRIES = gql`
  query GerAccountUserCountries {
    accountUserCountries
  }
`

export const GET_ACCOUNT_USERS_SIMPLE = gql`
  query GetAccountUsers(
    $search: String
    $projectId: ID
    $accountType: AccountVariantChoices
    $accountTypes: [AccountVariantChoices]
    $role: AccountUserRoleChoices
    $country: CountryChoices
    $accountId: ID
    $offset: Int
    $limit: Int
  ) {
    accountUsers(
      search: $search
      accountType: $accountType
      projectId: $projectId
      accountId: $accountId
      accountTypes: $accountTypes
      role: $role
      country: $country
      offset: $offset
      limit: $limit
    ) {
      accountUsers {
        ...AccountUserTypeFragment
      }
      totalCount
      creatorsCount
      expertsCount
      vendorsCount
      gembahCount
    }
  }
  ${ACCOUNT_USER_TYPE_FRAGMENT}
`

export const GET_ACCOUNT_USERS = gql`
  query GetAccountUsers(
    $search: String
    $accountType: AccountVariantChoices
    $role: AccountUserRoleChoices
    $country: CountryChoices
    $accountId: ID
    $offset: Int
    $limit: Int
  ) {
    usersSearch @client @export(as: "search")
    externalUsersFilter @client @export(as: "accountId")
    internalUsersFilterByRole @client @export(as: "role")
    internalUsersFilterByLocation @client @export(as: "country")
    paginationOffset @client @export(as: "offset")
    paginationLimit @client @export(as: "limit")
    accountUsers(
      search: $search
      accountType: $accountType
      accountId: $accountId
      role: $role
      country: $country
      offset: $offset
      limit: $limit
    ) {
      accountUsers {
        ...AccountUserTypeFragment
      }
      totalCount
    }
  }
  ${ACCOUNT_USER_TYPE_FRAGMENT}
`

export const GET_ACCOUNTS_BY_TYPE = gql`
  query GetAccounts($accountTypes: [AccountVariantChoices], $search: String, $limit: Int) {
    accounts(accountTypes: $accountTypes, search: $search, limit: $limit) {
      accounts {
        id
        type
        name
        accountUsers {
          id
          roles {
            id
            role
          }
        }
      }
      totalCount
    }
  }
`

export const GET_ACCOUNTS = gql`
  query GetAccounts(
    $search: String
    $accountTypes: [AccountVariantChoices]
    $orderBy: [String]
    $offset: Int
    $limit: Int
  ) {
    companiesSearch @client @export(as: "search")
    companiesFilterByType @client @export(as: "accountTypes")
    companiesOrderBy @client @export(as: "orderBy")
    paginationOffset @client @export(as: "offset")
    paginationLimit @client @export(as: "limit")
    accounts(
      search: $search
      accountTypes: $accountTypes
      orderBy: $orderBy
      offset: $offset
      limit: $limit
    ) {
      accounts {
        id
        type
        name
        accountUsers {
          id
          firstName
          lastName
          fullName
          email
          roles {
            id
            role
          }
        }
        accountProfile {
          id
          name
        }
      }
      totalCount
    }
  }
`

export const GET_ACCOUNT = gql`
  query GetAccount($accountId: ID!) {
    account(accountId: $accountId) {
      id
      type
      accountUsers {
        id
        fullName
        email
        status
        roles {
          id
          role
        }
      }
      accountProfile {
        phone
        phoneType
        website
        name
        phone
        primaryLocation {
          name
          longitude
          latitude
          country
          locality
          region
        }
      }
    }
  }
`

export const GET_GEMBAH_USERS = gql`
  query GetGembahUsers {
    gembahUsers {
      id
      user {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`

export const GET_USERS_BY_PROJECT = gql`
  query ($projectId: ID) {
    availableUsers(projectId: $projectId) {
      id
      firstName
      lastName
      user {
        groups
        firstName
        lastName
      }
      account {
        type
      }
    }
  }
`
