import { useMutation, useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'

import DefaultImage from '@public/images/no-image-default.png'
import MaskIcon from '@public/svg/icons/mask-icon.svg'
import PaperPlaneIcon from '@public/svg/icons/paper-plane.svg'
import DeleteIcon from '@public/svg/icons/trash.svg'
import BagIcon from '@public/svg/icons/working.svg'

import GembahConfirmDialog from '@dialog/GembahConfirmDialog'
import GembahReassignProjectsModal from '@dialog/GembahReassignProjectsModal'

import CancelButton from '@forms/CancelButton'
import PopButton from '@forms/PopButton'

import { currentAccountUser, featureFlags } from '@lib/apollo/apolloCache'
import {
  COMMON_WHITE,
  DARKER_GRAY,
  GRAY_NICKELARCADE,
  GRAY_WII,
  GREEN_TMNT,
  RED_JEM,
  RED_STRAWBERRY_SHORT_CAKE,
  TEAL_DOLLHOUSE,
  TEAL_FURBY,
} from '@lib/colors'
import { COUNTRIES } from '@lib/constants'
import { getCountryImageUrl } from '@lib/dataUtils'
import { FONTS, MOBILE_BREAKPOINT } from '@lib/theme'
import { hasPermission, isAdminManager, USER_ROLES } from '@lib/userAuth'

import { DELETE_ACCOUNT_USER, UPDATE_ACCOUNT_USER } from '@graphql/user/mutators'
import { GET_AUTH_USER } from '@graphql/user/queries'
import { RESEND_USER_INVITE } from '@graphql/users/mutators'
import { GET_ACCOUNT_USERS } from '@graphql/users/queries'

import Permissions from '@components_pop/AdminConsole/Shared/Permissions'
import { MemberRowBadge } from '@components_pop/AdminConsole/styles'
import { masqueradeUser, normalizeUser } from '@components_pop/AdminConsole/utils'
import { DrawerFooterStyles } from '@components_pop/PopDrawer'
import { TOAST_TYPES } from '@components_pop/Toast'
import UserLetterAvatar from '@components_pop/users/UserLetterAvatar'

import useToast from '@hooks/useToast'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 20,
    flexDirection: 'column',
    background: GRAY_WII,
    height: '100%',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
    },
  },
  leftSide: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: '27%',
      marginRight: '3%',
    },
  },
  countryDetails: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 18,
      marginRight: 10,
    },
  },
  userDetails: {
    borderBottom: `1px solid ${COMMON_WHITE}`,
    display: 'flex',
    paddingBottom: 40,
    marginBottom: 40,
    '& > div:first-child': {
      width: 65,
      height: 100,
      borderRadius: 30,
      overflow: 'hidden',
      marginRight: 15,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
      '& .MuiAvatar-root': {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        background: TEAL_FURBY,
        fontSize: 32,
        fontWeight: 700,
        fontFamily: FONTS.MERRI_WEATHER,
        textTransform: 'uppercase',
      },
    },
    '& > div:last-child': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: 12,
      color: TEAL_FURBY,
      overflow: 'hidden',
      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      },
      '& > span:first-of-type': {
        fontFamily: FONTS.MERRI_WEATHER,
        color: TEAL_DOLLHOUSE,
        fontWeight: 700,
        fontSize: 18,
        marginBottom: 5,
      },
    },
  },
  userMisc: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& > div > span': {
      fontSize: 12,
      color: GRAY_NICKELARCADE,
    },
  },
  userMiscAlternate: {
    '& > div > span': {
      color: RED_JEM,
    },
  },
  rightSide: {
    width: '100%',
    borderRadius: 15,
    background: COMMON_WHITE,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: '70%',
    },
  },
  permissionsTitle: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
    '& span:first-child': {
      fontSize: 24,
      fontWeight: 600,
      color: TEAL_FURBY,
      marginBottom: 5,
    },
    '& span:last-child': {
      fontSize: 10,
      color: GRAY_NICKELARCADE,
    },
  },
  permissionWrapper: {
    padding: '0 20px',
  },
  bottomBanner: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 20px',
    background: DARKER_GRAY,
    color: GRAY_NICKELARCADE,
    fontSize: 10,
  },
  noPermissionText: {
    fontSize: 14,
    color: GREEN_TMNT,
    fontStyle: 'italic',
    width: '100%',
    textAlign: 'end',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > button:first-child': {
      marginRight: 10,
    },
  },
}))

const UserDrawerContent = ({ user, onClose, hasOwner, showUserType = true }) => {
  const { isMasqueradeActive } = useReactiveVar(featureFlags)
  const viewingUser = useReactiveVar(currentAccountUser)

  const [isConfirmingDeleteUserModal, setIsConfirmingDeleteUserModal] = useState(false)
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false)

  const classes = useStyles()
  const { addToast } = useToast()

  const { control, setValue, handleSubmit, watch } = useForm()

  const { permission, confirmOwner } = watch()

  const {
    id,
    userId,
    fullName,
    firstName,
    lastName,
    lastLogin,
    email,
    avatar,
    invitedAt,
    invitedBy,
    maxRole: role,
    isLastAccountUser,
    isInvited,
    userType,
    countryCode,
    companyName,
    isUserGembah,
  } = normalizeUser(user)

  const isViewingUserGembah = hasPermission.GEMBAH(viewingUser)
  const hasPermissionToEdit = isAdminManager(viewingUser, user)

  const hasOwnerConfirmation =
    isUserGembah || permission !== USER_ROLES.OWNER || !hasOwner || confirmOwner

  const isOwnerAndCannotBeChanged = !isUserGembah && hasPermission.OWNER(user)
  const hasOwnerEditPermission = isViewingUserGembah
    ? hasPermission.ADMIN(viewingUser)
    : hasPermission.OWNER(viewingUser)
  const disabledPermissions = {
    [USER_ROLES.OWNER]: isOwnerAndCannotBeChanged || !hasOwnerEditPermission,
    [USER_ROLES.ADMIN]: isOwnerAndCannotBeChanged,
    [USER_ROLES.MEMBER]: isOwnerAndCannotBeChanged,
  }

  const [resendUserInvite, { loading: isResending }] = useMutation(RESEND_USER_INVITE)
  const [deleteAccountUser, { loading: isDeleting }] = useMutation(DELETE_ACCOUNT_USER, {
    refetchQueries: [
      isViewingUserGembah
        ? { query: GET_ACCOUNT_USERS, variables: { accountType: userType } }
        : { query: GET_AUTH_USER },
    ],
  })
  const [updateAccountUser, { loading: isUpdating }] = useMutation(UPDATE_ACCOUNT_USER, {
    refetchQueries: [
      isViewingUserGembah
        ? { query: GET_ACCOUNT_USERS, variables: { accountType: userType } }
        : { query: GET_AUTH_USER },
    ],
  })

  const handleUpdateAccountUser = ({ permission: currentPermission }) => {
    updateAccountUser({
      variables: {
        accountUserId: id,
        roleInput: { role: currentPermission },
      },
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could not update user permission',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: 'Successfully updated user permission',
        type: TOAST_TYPES.SUCCESS,
      })
      onClose()
    })
  }

  const handleDeleteAccountUser = () => {
    deleteAccountUser({
      variables: {
        accountUserId: id,
      },
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could delete user',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: 'User has been deleted',
        type: TOAST_TYPES.SUCCESS,
      })
      onClose()
    })
  }

  const handleResendUserInvite = () => {
    resendUserInvite({ variables: { userEmail: email } }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could not resend invite',
          type: TOAST_TYPES.ERROR,
        })
        return
      }

      addToast({
        message: `Invite has been sent to ${email}`,
        type: TOAST_TYPES.SUCCESS,
      })
    })
  }

  useEffect(() => {
    if (role) {
      const value = role.role === USER_ROLES.BILLING ? USER_ROLES.ADMIN : role.role
      setValue('permission', value)
    }
  }, [role?.role, setValue])

  return (
    <>
      {showUserType && <MemberRowBadge usertype={userType}>{userType}</MemberRowBadge>}
      <div className={classes.root}>
        <div className={classes.leftSide}>
          <div className={classes.userDetails}>
            <div>
              {isInvited ? (
                <img src={DefaultImage.src} alt="invited" />
              ) : (
                <UserLetterAvatar
                  alt={fullName}
                  src={avatar?.url}
                  firstName={firstName}
                  lastName={lastName}
                />
              )}
            </div>
            <div>
              <span style={{ fontSize: isInvited ? 12 : 18 }}>{isInvited ? email : fullName}</span>
              <span>{companyName}</span>
              <span>{isInvited ? null : email}</span>
              {!isInvited && (
                <span className={classes.countryDetails}>
                  <img src={getCountryImageUrl(countryCode)} alt={countryCode} />
                  {COUNTRIES[countryCode]}
                </span>
              )}
            </div>
          </div>

          <div
            className={clsx(classes.userMisc, {
              [classes.userMiscAlternate]: isInvited,
            })}
          >
            <div>
              {isInvited ? (
                <>
                  <span>Invited, Not Yet Responded</span>
                  {hasPermissionToEdit && (
                    <div className={classes.actions}>
                      <PopButton
                        onClick={handleResendUserInvite}
                        btnStyle="simple"
                        size="small"
                        svgIconLeft={PaperPlaneIcon}
                        iconHeight={20}
                        disabled={isResending}
                        loading={isResending}
                      >
                        resend invite
                      </PopButton>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <span>
                    Last Activity: {lastLogin ? moment(lastLogin).format('MMM DD, YYYY') : '-'}
                  </span>
                  {hasPermissionToEdit &&
                    isViewingUserGembah &&
                    (isMasqueradeActive || isUserGembah) && (
                      <div className={classes.actions}>
                        {isMasqueradeActive && (
                          <PopButton
                            btnStyle="simple"
                            size="small"
                            iconHeight={20}
                            svgIconLeft={MaskIcon}
                            onClick={() => masqueradeUser(userId)}
                          >
                            masquerade
                          </PopButton>
                        )}
                        {isUserGembah && (
                          <PopButton
                            btnStyle="simple"
                            size="small"
                            iconHeight={20}
                            svgIconLeft={BagIcon}
                            onClick={() => setIsReassignModalOpen(true)}
                          >
                            reassign work
                          </PopButton>
                        )}
                      </div>
                    )}
                </>
              )}
            </div>

            {hasPermissionToEdit && !isLastAccountUser ? (
              <PopButton
                onClick={() => setIsConfirmingDeleteUserModal(true)}
                size="small"
                btnStyle="simple"
                style={{ color: RED_STRAWBERRY_SHORT_CAKE }}
                iconHeight={20}
                svgIconLeft={DeleteIcon}
                disabled={isDeleting}
              >
                delete user
              </PopButton>
            ) : (
              <span />
            )}
          </div>
        </div>

        <div className={classes.rightSide}>
          <div className={classes.permissionsTitle}>
            <span>Permissions</span>
            <span>Select the level of access for this user.</span>
          </div>

          <div className={classes.permissionWrapper}>
            <Permissions
              userType={userType}
              control={control}
              hasOwner={hasOwner}
              disableAll={!hasPermissionToEdit}
              disabledPermissions={disabledPermissions}
            />
          </div>
        </div>
      </div>
      {invitedAt && invitedBy && (
        <div className={classes.bottomBanner}>
          Invited By: {invitedBy.fullName} on {moment(invitedAt).format('MMM DD, YYYY')}
        </div>
      )}
      <DrawerFooterStyles>
        {hasPermissionToEdit ? (
          <>
            <CancelButton onClick={onClose} disabled={isUpdating}>
              cancel changes
            </CancelButton>
            <PopButton
              onClick={handleSubmit(handleUpdateAccountUser)}
              loading={isUpdating}
              disabled={isUpdating || !hasOwnerConfirmation}
            >
              save changes
            </PopButton>
          </>
        ) : (
          <span className={classes.noPermissionText}>
            {user?.id === viewingUser?.id
              ? 'You do not have permissions to make changes to yourself'
              : 'You do not have permissions to make changes to this user'}
          </span>
        )}
        {isConfirmingDeleteUserModal && (
          <GembahConfirmDialog
            title="Delete user?"
            description="This will delete the user from the platform"
            onAccept={() => handleDeleteAccountUser()}
            closeButtonLabel="Cancel"
            isSubmitting={isDeleting}
            onClose={() => setIsConfirmingDeleteUserModal(false)}
          />
        )}
        {isReassignModalOpen && (
          <GembahReassignProjectsModal
            userFrom={user}
            onClose={() => setIsReassignModalOpen(false)}
            onSuccessCallback={() => setIsReassignModalOpen(false)}
          />
        )}
      </DrawerFooterStyles>
    </>
  )
}

UserDrawerContent.propTypes = {
  user: PropTypes.object,
  showUserType: PropTypes.bool,
  hasOwner: PropTypes.bool,
  onClose: PropTypes.func,
}

export default UserDrawerContent
